// File: api.js
import axios from 'axios';
import {clearAuthViaContext} from './context/AppContext';

// Function to get UTM source from URL
const getUTMSource = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('utm_source') || null;
};

// Function to save UTM source in sessionStorage
const saveUTMSource = () => {
    const utmSource = getUTMSource();
    if (utmSource) {
        sessionStorage.setItem('utm_source', utmSource);
    }
};

// Function to retrieve stored UTM source from sessionStorage
const getStoredUTMSource = () => {
    return sessionStorage.getItem('utm_source') || null;
};

// Function to update the URL with UTM source while keeping other query params
export const updateURLWithUTM = () => {
    const utmSource = getStoredUTMSource();
    if (!utmSource) return;

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    // If utm_source is already in the URL, do nothing
    if (params.get('utm_source') === utmSource) return;

    // Add utm_source while keeping existing query params
    params.set('utm_source', utmSource);
    window.history.replaceState({}, '', `${url.pathname}?${params.toString()}`);
};

// Save UTM source and update URL on page load
saveUTMSource();
updateURLWithUTM();

const api = axios.create({
    baseURL: 'https://marketplace.dating/api',
});

// Response interceptor to handle 401 errors
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            clearAuthViaContext();
        }
        return Promise.reject(error);
    }
);

export default api;
