import React from 'react';

const Disclaimer = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1>ОТКАЗ ОТ ОТВЕТСТВЕННОСТИ (Disclaimer)</h1>
            <p>г. Москва «01» января 2025 г.</p>
            <p>
                Настоящий отказ от ответственности (далее - Отказ) является неотъемлемой частью
                Соглашения об использовании Сайта, Контента и Сервисов Сайта в сети «Интернет»
                (пользовательское соглашение, оферта), размещенного на сайте в сети Интернет по адресу:
                <a href="https://marketplace.dating" target="_blank" rel="noopener noreferrer">https://marketplace.dating </a>
                (далее - Сайт) и знакомясь с настоящим Отказом Пользователь подтверждает ознакомление с Соглашением со всеми приложениями и принятие его условий.
            </p>
            <p>
                Используя Сайт <a href="https://marketplace.dating" target="_blank" rel="noopener noreferrer">https://marketplace.dating </a>
                Пользователь выражаете своё согласие с настоящим «Отказом от ответственности». Если Пользователь не согласен с каким-либо положением «Отказа от ответственности», Пользователю следует отказаться от использования Сайта.
            </p>
            <p>
                Вся информация (Контент) на Сайте <a href="https://marketplace.dating" target="_blank" rel="noopener noreferrer">https://marketplace.dating </a>
                публикуется добросовестно и исключительно в целях общего ознакомления. Администратор не дает никаких гарантий относительно полноты, достоверности и точности информации (Контента), предоставленной самим Пользователем. Любые действия, которые Пользователь предпринимает в отношении информации (Контента), найденной на этом Сайте, совершаются исключительно на страх и риск Пользователя. Администратор не несет ответственности за любые убытки и/или ущерб, связанные с использованием Сайта.
            </p>
            <p>
                Ссылки на другие Сайты, ссылки на номера мессенджеров «WhatsApp» и «Telegram» Пользователей не означают рекомендации ко всей информации (Контенту), найденному на этих Сайтах и в указанных мессенджерах. Администратор оставляет за собой право по своему усмотрению удалить любой материал (Контент), размещённый на данном Сайте, удалить Личный кабинет/аккаунт, ограничить доступ к Сайту.
            </p>
            <p>
                В отношении всех размещаемых на данном Сайте материалов (Контента) Пользователь даете Администратору повсеместное, свободное от обязательств право на их использование, воспроизведение, адаптацию, публикацию, перевод и распространение посредством любых видов связи и вещания.
            </p>
            <p>
                При этом, Пользователь не имеет право воспроизводить, копировать, размножать, продавать, перепродавать или каким-либо иным образом использовать Сайт и размещенную информацию (Контент) в коммерческих целях без письменного согласия, не имеет право использовать Сайт любым образом, прямо или опосредованно ведущим к нарушению законодательства, причинению вреда или совершению противозаконных действий.
            </p>
            <p>
                Отказ от ответственности носит исчерпывающий характер в отношении использования Сайта, и имеет приоритет над любыми другими соглашениями в отношении использования Сайта.
            </p>
        </div>
    );
};

export default Disclaimer;
