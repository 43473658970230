import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import axios from 'axios';
import api from '../../api';
import { useAppContext } from '../../context/AppContext';

const API_KEY_DADATA = process.env.REACT_APP_API_KEY_DADATA;

function ProfileForm() {
    const navigate = useNavigate();
    const {getValue} = useAppContext(); // Get auth token

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');

    const [telegram, setTelegram] = useState('');
    const [whatsApp, setWhatsApp] = useState('');
    const [phone, setPhone] = useState('');

    const [cityId, setCityId] = useState('');

    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const [address, setAddress] = useState('');
    const [suggestions, setSuggestions] = useState([]); // Список предложений DaData

    const [subway, setSubway] = useState({});
    const [moscowSubways, setMoscowSubways] = useState([]);
    const [spbSubways, setSpbSubways] = useState([]);
    const getCitySubways = () => (cityId === 1 ? moscowSubways : spbSubways);

    const [bust, setBust] = useState('');
    const [height, setHeight] = useState('');
    const [foot, setFoot] = useState('');
    const [weight, setWeight] = useState('');

    const [isPhotoLoading, setIsPhotoLoading] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(true);

    const [photos, setPhotos] = useState([]);
    const [signaCode, setSignaCode] = useState([]);

    // Массив для размера груди (1..5 c шагом 0.5)
    const chestOptions = [];
    for (let val = 1; val <= 5; val += 0.5) {
        // Чтобы избежать неточностей float, используем toFixed(1)
        const numericVal = parseFloat(val.toFixed(1));
        chestOptions.push(numericVal);
    }
    chestOptions.push('5+');

    // Массив для роста (150..200)
    const heightOptions = [];
    for (let h = 150; h <= 200; h++) {
        heightOptions.push(h);
    }

    // UI States
    const [loading, setLoading] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const authToken = getValue('authToken');

                if (!authToken) {
                    return;
                }

                // Make GET request to fetch private profile data
                const response = await api.get('/privateProfile', {
                    headers: {Authorization: `Bearer ${authToken}`},
                });

                const profileData = response.data;

                setName(profileData.name || '');
                setDescription(profileData.description || '');
                setPrice(profileData.price || '');

                setTelegram(profileData.telegram || '');
                if (profileData.whatsApp) {
                    // Убираем все символы, если первая цифра 7 или 8 – отрезаем её,
                    // чтобы маска заполнилась корректно (будет добавлена фиксированно +7)
                    let digits = profileData.whatsApp.replace(/\D/g, '');
                    if (digits[0] === '7' || digits[0] === '8') {
                        digits = digits.substring(1);
                    }
                    setWhatsApp(digits);
                }
                if (profileData.phone) {
                    // Убираем все символы, если первая цифра 7 или 8 – отрезаем её,
                    // чтобы маска заполнилась корректно (будет добавлена фиксированно +7)
                    let digits = profileData.phone.replace(/\D/g, '');
                    if (digits[0] === '7' || digits[0] === '8') {
                        digits = digits.substring(1);
                    }
                    setPhone(digits);
                }

                const subwayResponse = await api.get('/utils/subways');
                const subwaysData = subwayResponse.data;

                setCityId(profileData.cityId || 1);
                setMoscowSubways(
                    subwaysData
                        .filter((s) => s.cityId === 1)
                        .filter((s) => s.name && s.name.trim() !== "")
                        .filter((subway, index, self) =>
                            self.findIndex((item) => item.name === subway.name) === index
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                );
                setSpbSubways(
                    subwaysData
                        .filter((s) => s.cityId === 2)
                        .filter((s) => s.name && s.name.trim() !== "")
                        .filter((subway, index, self) =>
                            self.findIndex((item) => item.name === subway.name) === index
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                );

                setSubway(profileData.subway || {});
                setAddress(profileData.address || '');
                setLatitude(profileData.latitude || '');
                setLongitude(profileData.longitude || '');

                setBust(profileData.bust || '');
                setHeight(profileData.height || '');
                setFoot(profileData.foot || '');
                setWeight(profileData.weight || '');

                setSignaCode(profileData.signaCode)
                setPhotos(profileData.photos);

                setIsProfileLoading(false)
            } catch (error) {
                alert('Ошибка загрузки. Перезагрузите страницу.');
                setIsProfileLoading(false)
            }
        };
        fetchData();

        // Добавляем в <head> анимацию progress bar
        const styleTag = document.createElement('style');
        styleTag.innerHTML = progressKeyframes;
        document.head.appendChild(styleTag);

        return () => {
            document.head.removeChild(styleTag);
        };
    }, []);

    const fetchSuggestions = async (query) => {
        if (!query) return;
        try {
            const response = await axios.post(
                'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
                {query},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${API_KEY_DADATA}`,
                    },
                }
            );
            setSuggestions(response.data.suggestions || []);
        } catch (error) {
            // console.error('Error fetching address suggestions:', error);
        }
    };

    // Обработчик ввода в поле адреса
    const handleSuggestionInputChange = (e) => {
        const value = e.target.value;
        setAddress(value);
        if (value.length > 2) fetchSuggestions(value);
        else setSuggestions([]);
    };

    // Обработчик выбора адреса
    const handleSelectSuggestion = (suggestion) => {
        setAddress(suggestion.value);
        setLongitude(suggestion.data['geo_lon']);
        setLatitude(suggestion.data['geo_lat']);
        setSuggestions([]);
    };

    // При смене города сбросить станцию, если она не подходит
    useEffect(() => {
        const cityStations = getCitySubways();
        if (!cityStations.some((st) => st.id === subway.id)) {
            setSubway({});
        }
        // eslint-disable-next-line
    }, [cityId]);

    const runRequestWithMinDelay = async (requestFn) => {
        setIsPhotoLoading(true);
        const start = Date.now();
        try {
            await requestFn();
        } finally {
            const elapsed = Date.now() - start;
            const minimum = 1000; // 1 сек
            const remaining = minimum - elapsed;
            if (remaining > 0) {
                await new Promise((res) => setTimeout(res, remaining));
            }
            setIsPhotoLoading(false);
        }
    };

    // -------------------- ЗАГРУЗКА ОБЫЧНЫХ ФОТО --------------------
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);

        const authToken = getValue('authToken');
        if (!authToken) {
            return;
        }

        // Проверка на общее количество (не более 10)
        const existingPhotos = photos.filter((p) => !p.isForVerification) || [];
        if (existingPhotos.length + selectedFiles.length > 10) {
            alert('Максимум 10 фото!');
            return;
        }

        // Загружаем все фото последовательно
        runRequestWithMinDelay(async () => {
            for (let file of selectedFiles) {
                const formData = new FormData();
                formData.append('file', file);

                try {
                    const response = await api.post('/privateProfile/photo', formData, {
                        headers: {Authorization: `Bearer ${authToken}`},
                    });
                    const newPhoto = response.data;
                    setPhotos(prevPhotos => [...prevPhotos, newPhoto]);
                } catch (error) {
                    if (error.response) {
                        // Сервер вернул ответ с ошибкой
                        alert(`Ошибка при загрузке фото: ${error.response.data || 'Неизвестная ошибка'}`);
                    } else {
                        // Ошибка на стороне клиента
                        alert('Ошибка загрузки фото. Попробуйте позже.');
                    }
                }

            }
        });
    };

    // -------------------- ЗАГРУЗКА ПРОВЕРОЧНЫХ ФОТО --------------------
    const handleVerificationFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);

        const authToken = getValue('authToken');
        if (!authToken) {
            return;
        }

        runRequestWithMinDelay(async () => {
            for (let file of selectedFiles) {
                const formData = new FormData();
                formData.append('file', file);

                try {
                    const response = await api.post('/privateProfile/photoverification', formData, {
                        headers: {Authorization: `Bearer ${authToken}`},
                    });
                    const newPhoto = response.data;
                    setPhotos(prevPhotos => [...prevPhotos, newPhoto]);
                } catch (error) {
                    if (error.response) {
                        alert(`Ошибка при загрузке фото: ${error.response.data || 'Неизвестная ошибка'}`);
                    } else {
                        alert('Ошибка загрузки фото. Попробуйте позже.');
                    }
                }
            }
        });
    };

    // -------------------- УДАЛЕНИЕ ФОТО --------------------
    const handleRemove = (photoId) => {
        if (!window.confirm('Удалить это фото?')) return;

        const authToken = getValue('authToken');
        if (!authToken) {
            return;
        }

        runRequestWithMinDelay(async () => {
            await api.delete(`/privateProfile/photo/${photoId}`, {
                headers: {Authorization: `Bearer ${authToken}`},
            });

            // Check if the deleted photo was the one marked as "isPrimary"
            const removedPhoto = photos.find((photo) => photo.id === photoId);
            const updatedPhotos = photos.filter((photo) => photo.id !== photoId);

            // If the removed photo was the primary one, update the first photo (if any)
            if (removedPhoto?.isPrimary && updatedPhotos.length > 0) {
                // Mark the first photo as primary
                updatedPhotos[0].isPrimary = true;
            }

            // Update the state with the new list of photos
            setPhotos(updatedPhotos);
        });
    };

    // -------------------- УСТАНОВКА ГЛАВНОГО ФОТО --------------------
    const handleSetPrimary = (photoId) => {
        const authToken = getValue('authToken');
        if (!authToken) {
            return;
        }

        runRequestWithMinDelay(async () => {
            await api.post(
                `/privateProfile/photo/primary/${photoId}`,
                {},
                {
                    headers: {Authorization: `Bearer ${authToken}`},
                }
            );
            setPhotos(photos.map((p) => ({
                ...p,
                isPrimary: p.id === photoId,
            })));
        });
    };

    // -------------------- Разделение фото на Обычные / Проверочные --------------------
    const normalPhotos = Array.isArray(photos) ? photos.filter((p) => p.isForVerification === false) : [];
    const verificationPhotos = Array.isArray(photos) ? photos.filter((p) => p.isForVerification === true) : [];

    const validateForm = () => {
        setInvalidFields([]);
        const newInvalidFields = [];

        if (!name) newInvalidFields.push('name');
        if (!description) newInvalidFields.push('description');
        if (price === '') {
            newInvalidFields.push('price');
        } else if (Number(price) < 3000) {
            newInvalidFields.push('price');
        }

        if (!telegram && !whatsApp && !phone) {
            newInvalidFields.push('telegram');
            newInvalidFields.push('whatsApp');
            newInvalidFields.push('phone');
        }

        if (!address) newInvalidFields.push('address');
        if (!subway.id) newInvalidFields.push('subway');

        if (!bust) newInvalidFields.push('bust');
        if (!height) newInvalidFields.push('height');
        if (!foot) newInvalidFields.push('foot');
        if (!weight) newInvalidFields.push('weight');

        if (normalPhotos.length === 0) newInvalidFields.push('photos')

        if (newInvalidFields.length > 0) {
            if (!telegram && !whatsApp && !phone) {
                alert('Хотя бы один способ связи должен быть указан.');
            } else if (normalPhotos.length === 0){
                alert("Загрузите хотя бы 1 фото.")
            } else if (verificationPhotos.length < 2) {
                alert('Загрузите 2 проверочных фото (фото с листком и фото с лицом).');
            } else {
                alert('Пожалуйста, заполните все обязательные поля.');
            }
            setInvalidFields(newInvalidFields);
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setLoading(true);

        // Add a small artificial delay to demonstrate the loader
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const authToken = getValue('authToken');
        if (!authToken) {
            alert('Ошибка авторизации. Попробуйте снова.');
            setLoading(false);
            return;
        }

        const updatedProfile = {
            name,
            description,
            price,
            telegram,
            whatsApp: whatsApp ? `+${whatsApp}` : '',
            phone: phone ? `+${phone}` : '',
            cityId,
            address,
            longitude,
            latitude,
            subwayId: subway.id,
            bust,
            height,
            foot,
            weight
        };

        try {
            await api.put('/privateProfile', updatedProfile, {
                headers: {Authorization: `Bearer ${authToken}`},
            });

            alert('Анкета отправлена на модерацию! Модерация обычно занимает до 24 часов.');
            navigate('/me/dashboard');
        } catch (err) {
            alert('Ошибка при обновлении профиля. Попробуйте снова.');
        } finally {
            setLoading(false);
        }
    };

    // Styles (Restored Exactly as They Were)
    const containerStyle = {
        fontFamily: 'Montserrat, sans-serif',
        maxWidth: '600px',
        margin: '0 auto',
        padding: '32px',
        lineHeight: '1.6',
        color: '#000',
    };

    const headingStyle = {
        fontSize: '2rem',
        fontWeight: '700',
        marginBottom: '24px',
        textAlign: 'center',
        color: '#000',
    };

    const subHeadingStyle = {
        fontSize: '1.6rem',
        fontWeight: '700',
        marginBottom: '24px',
        textAlign: 'left',
        color: '#000',
    };

    const labelStyle = {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '24px',
        fontSize: '1.05rem',
        fontWeight: '600',
    };

    const baseInputStyle = {
        padding: '14px',
        borderRadius: '12px',
        border: '1px solid #ccc',
        fontSize: '1rem',
        outline: 'none',
        backgroundColor: '#f9f9f9',
        transition: 'border-color 0.3s',
    };

    const baseSelectStyle = {
        padding: '14px',
        borderRadius: '12px',
        border: '1px solid #ccc',
        fontSize: '1rem',
        outline: 'none',
        backgroundColor: '#f9f9f9',
        transition: 'border-color 0.3s',
        width: '100%',
    };

    const getFieldStyle = (fieldName) => {
        const isInvalid = invalidFields.includes(fieldName);
        return {
            ...baseSelectStyle,
            borderColor: isInvalid ? 'red' : '#ccc',
        };
    };

    const dropdownStyle = {
        ...baseInputStyle,
        width: '100%',
    };

    const getInputStyle = (fieldName) => ({
        ...baseInputStyle,
        borderColor: invalidFields.includes(fieldName) ? 'red' : baseInputStyle.borderColor,
    });

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '48px',
    };

    const exitButtonContainerStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '16px',
    };

    const buttonStyle = {
        padding: '14px 24px',
        fontSize: '1.2rem',
        fontWeight: '900',
        color: '#fff',
        backgroundColor: '#000',
        border: 'none',
        borderRadius: '12px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const exitButtonStyle = {
        padding: '6px 12px',
        fontSize: '1.9rem',
        fontWeight: '900',
        color: '#fff',
        backgroundColor: '#000',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const exitButtonHoverStyle = {
        backgroundColor: '#000',
    };

    const descriptionStyle = {
        marginBottom: '24px',
        fontSize: '1.05rem',
        color: '#333',
    };

    const progressContainerStyle = {
        width: '100%',
        height: '6px',
        backgroundColor: '#e0e0e0',
        marginBottom: '16px',
        overflow: 'hidden',
        position: 'relative',
    };

    const progressIndicatorStyle = {
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        animation: 'progress-animation 1s linear infinite',
        transform: 'translateX(-100%)',
    };

    // Ключевые кадры анимации
    const progressKeyframes = `
        @keyframes progress-animation {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }`
    ;

    const radioContainerStyle = {
        marginTop: '4px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
    };

    const fileInputLabelStyle = {
        display: 'inline-block',
        padding: '12px 20px',
        fontSize: '1rem',
        fontWeight: '600',
        color: '#fff',
        backgroundColor: '#000',
        borderRadius: '8px',
        cursor: 'pointer',
        marginBottom: '16px',
        textAlign: 'center',
        maxWidth: '100%',
        transition: 'background-color 0.3s ease',
    };

    // Чтобы скрыть реальный input
    const hiddenFileInputStyle = {
        display: 'none',
    };

    const photosContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',
    };

    const photoWrapperStyle = {
        position: 'relative',
        width: '120px',
        height: '120px',
        marginTop: '24px',
        borderRadius: '8px',
    };

    const previewImageStyle = {
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        objectFit: 'cover',
    };

    const removeButtonStyle = {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: '#f00',
        color: '#fff',
        border: 'none',
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        cursor: 'pointer',
    };

    return (
        <div style={containerStyle}>
            <div style={exitButtonContainerStyle}>
                <button
                    style={exitButtonStyle}
                    onClick={() => navigate('/me/dashboard')}
                    onMouseOver={(e) => Object.assign(e.target.style, exitButtonHoverStyle)}
                    onMouseOut={(e) => Object.assign(e.target.style, exitButtonStyle)}
                >
                    ←
                </button>
            </div>

            <h1 style={headingStyle}>Заполните анкету</h1>

            {isProfileLoading && (
                <div style={progressContainerStyle}>
                    <div style={progressIndicatorStyle}/>
                </div>
            )}

            <h2 style={subHeadingStyle}>Основная информация</h2>

            <label style={labelStyle}>
                Имя (макс. 30 символов)
                <input type="text" maxLength={30} value={name} onChange={(e) => setName(e.target.value)}
                       style={getInputStyle('name')}/>
            </label>

            <label style={labelStyle}>
                Описание (макс. 1000 символов)
                <textarea maxLength={1000} value={description} onChange={(e) => setDescription(e.target.value)}
                          style={{...getInputStyle('description'), height: '150px', resize: 'none'}}/>
            </label>

            <label style={labelStyle}>
                Стоимость услуг (от 3000₽)
                <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    style={getInputStyle('price')}
                    placeholder="от 10000₽"
                    min="0"
                />
            </label>

            <h2 style={subHeadingStyle}>Контакты</h2>

            <label style={labelStyle}>
                Telegram
                <input
                    type="text"
                    value={telegram}
                    onChange={(e) => setTelegram(e.target.value)}
                    style={getInputStyle('telegram')}
                    placeholder="Например, model_kate"
                />
            </label>

            <label style={labelStyle}>
                WhatsApp
                <IMaskInput
                    mask="+{7} (000) 000-00-00"
                    unmask={true} // хранится значение без маски (только цифры динамической части)
                    value={whatsApp}
                    onAccept={(value) => setWhatsApp(value)}
                    placeholder="Например, +7 999 888-00-00"
                    style={getInputStyle('phone')}
                />
            </label>

            <label style={labelStyle}>
                Телефон
                <IMaskInput
                    mask="+{7} (000) 000-00-00"
                    unmask={true} // хранится значение без маски (только цифры динамической части)
                    value={phone}
                    onAccept={(value) => setPhone(value)}
                    placeholder="Например, +7 999 888-00-00"
                    style={getInputStyle('phone')}
                />
            </label>

            <h2 style={subHeadingStyle}>Локация</h2>
            <label style={labelStyle}>
                Город
                <select
                    value={cityId}
                    onChange={(e) => setCityId(Number(e.target.value))}
                    style={dropdownStyle}
                >
                    <option value={1}>Москва</option>
                    <option value={2}>Санкт-Петербург</option>
                </select>
            </label>

            <label style={labelStyle}>
                Адрес
                <input
                    type="text"
                    value={address}
                    onChange={handleSuggestionInputChange}
                    style={getInputStyle('address')}
                    placeholder="г. Москва, ул. Арбат, дом 1"
                />
                {suggestions.length > 0 && (
                    <ul
                        style={{
                            width: '100%',
                            background: '#fff',
                            listStyleType: 'none',
                            padding: 0,
                            margin: '4px 0',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            zIndex: 1000,
                            maxHeight: '200px',
                            overflowY: 'auto',
                            borderRadius: '8px',
                        }}
                    >
                        {suggestions.map((s, index) => (
                            <li
                                key={index}
                                onClick={() => handleSelectSuggestion(s)}
                                style={{
                                    padding: '10px',
                                    cursor: 'pointer',
                                    borderBottom: '1px solid #ddd',
                                    fontSize: '1rem',
                                }}
                            >
                                {s.value}
                            </li>
                        ))}
                    </ul>
                )}
            </label>

            <label style={labelStyle}>
                Ближайшая станция метро
                <select
                    value={subway.id || ''}
                    onChange={(e) => {
                        const idNum = Number(e.target.value);
                        const foundItem = getCitySubways().find((item) => item.id === idNum);
                        setSubway(foundItem || {});
                    }}
                    style={getInputStyle('subway')}
                >
                    <option value="">Выберите станцию</option>
                    {getCitySubways().map((sw) => (
                        <option key={sw.id} value={sw.id}>
                            {sw.name}
                        </option>
                    ))}
                </select>
            </label>

            <h2 style={subHeadingStyle}>Фигура</h2>

            <label style={labelStyle}>
                Бюст
                <select
                    value={bust}
                    onChange={(e) => setBust(e.target.value)}
                    style={getFieldStyle('bust')}
                >
                    <option value="" disabled hidden>Выберите размер груди</option>
                    {chestOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </label>

            <label style={labelStyle}>
                Рост
                <select
                    value={height}
                    onChange={(e) => setHeight(parseInt(e.target.value, 10))}
                    style={getFieldStyle('height')}
                >
                    <option value="" disabled hidden>Выберите рост</option>
                    {heightOptions.map((h) => (
                        <option key={h} value={h}>
                            {h}
                        </option>
                    ))}
                </select>
            </label>

            <label style={labelStyle}>
                Вес
                <select
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    style={getFieldStyle('weight')}>
                    >
                    <option value="" disabled hidden>Выберите вес</option>
                    {Array.from({length: 80}, (_, i) => 40 + i).map((val) => (
                        <option key={val} value={val}>{val}</option>
                    ))}
                </select>
            </label>

            <label style={labelStyle}>
                Размер ножки
                <select
                    value={foot}
                    onChange={(e) => setFoot(e.target.value)}
                    style={getFieldStyle('foot')}
                >
                    <option value="" disabled hidden>Выберите размер ножки</option>
                    {Array.from({length: 13}, (_, i) => 33 + i).map((val) => (
                        <option key={val} value={val}>{val}</option>
                    ))}
                </select>
            </label>

            <h2 style={subHeadingStyle}>Фото</h2>

            {/* Прогресс-бар при загрузке/удалении */}
            {isPhotoLoading && (
                <div style={progressContainerStyle}>
                    <div style={progressIndicatorStyle}/>
                </div>
            )}

            <h3 style={{marginTop: '32px', marginBottom: '8px'}}>
                Основные фото (публикуются в анкете)
            </h3>

            <p style={descriptionStyle}>
                Вы можете загрузить до 10 фотографий.
            </p>

            {/* Кнопка-лейбл для загрузки обычных фото */}
            <label
                htmlFor="normalPhotosInput"
                className="file-input-label"
                style={fileInputLabelStyle}
            >
                Загрузить обычные фото
            </label>
            <input
                id="normalPhotosInput"
                type="file"
                multiple
                accept="image/*"
                style={hiddenFileInputStyle}
                onChange={handleFileChange}
            />

            {/* Отображение обычных фото */}
            {normalPhotos.length > 0 && (
                <div style={photosContainerStyle}>
                    {normalPhotos.map((photo) => (
                        <div key={photo.id} style={photoWrapperStyle}>
                            <img
                                src={photo.path}
                                alt="preview"
                                onClick={() => handleSetPrimary(photo.id)}
                                style={previewImageStyle}/>
                            <button
                                onClick={() => handleRemove(photo.id)}
                                style={removeButtonStyle}
                            >
                                X
                            </button>
                            <div style={radioContainerStyle}>
                                <input
                                    type="radio"
                                    checked={photo.isPrimary || false}
                                    onChange={() => handleSetPrimary(photo.id)}
                                />
                                <span>Главная</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <h3 style={{marginTop: '64px', marginBottom: '8px'}}>
                Проверочные фото (не публикуются в анкете)
            </h3>

            {/* Описание в формате пунктов */}
            <ul style={{marginBottom: '16px', lineHeight: '1.5'}}>
                <li>Оригинал одной из фото в анкете, но с открытым лицом.</li>
                <li>
                    Фото с лицом и листком, на котором написано:{' '}
                    <strong>{signaCode}</strong>
                </li>
            </ul>

            {/* Кнопка-лейбл для загрузки проверочных фото */}
            <label
                htmlFor="verificationPhotosInput"
                className="file-input-label"
                style={fileInputLabelStyle}
            >
                Загрузить проверочные фото
            </label>
            <input
                id="verificationPhotosInput"
                type="file"
                multiple
                accept="image/*"
                style={hiddenFileInputStyle}
                onChange={handleVerificationFileChange}
            />

            {/* Отображение проверочных фото */}
            {verificationPhotos.length > 0 && (
                <div style={photosContainerStyle}>
                    {verificationPhotos.map((photo) => (
                        <div key={photo.id} style={photoWrapperStyle}>
                            <img src={photo.path} alt="verification" style={previewImageStyle}/>
                            <button
                                onClick={() => handleRemove(photo.id)}
                                style={removeButtonStyle}
                            >
                                X
                            </button>
                        </div>
                    ))}
                </div>
            )}

            <div style={buttonContainerStyle}>
                <button
                    onClick={handleSubmit}
                    disabled={loading}
                    style={buttonStyle}
                    data-umami-event="Submit Form Clicked"
                >
                    {loading ? 'Сохранение...' : 'Отправить анкету на модерацию'}
                </button>
            </div>
        </div>
    );
}

export default ProfileForm;
