import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import api from '../../api';
import {useAppContext} from '../../context/AppContext';

function AdminLogin() {
    const [loginValue, setLoginValue] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const {setValue} = useAppContext();

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        try {
            const requestData = {
                login: loginValue,
                password: password,
            };

            const response = await api.post('/auth/admin/login', requestData);

            if (response.data.success) {
                setValue('adminAuthToken', response.data.token)
                navigate('/hfwiehfwkuehfw98ef89wehfkwejhfkjwehfkjwehfkjwe/privates');
            } else {
                setErrorMessage(response.data.message || 'Login failed');
            }
        } catch (error) {
            // console.error('[Login] Error:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An unexpected error occurred');
            }
        }
    };

    // ---- STYLES (Unchanged) ----
    const layoutStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    };

    const contentStyle = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#fafafa',
        fontFamily: "'Montserrat', sans-serif"
    };

    const cardStyle = {
        width: '100%',
        maxWidth: '400px',
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    };

    const labelStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        fontSize: '0.9rem',
        color: '#333'
    };

    const inputStyle = {
        padding: '10px 12px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        fontSize: '0.95rem',
        outline: 'none'
    };

    const buttonStyle = {
        padding: '12px',
        borderRadius: '8px',
        border: 'none',
        fontSize: '1rem',
        fontWeight: '600',
        cursor: 'pointer',
        backgroundColor: '#000',
        color: '#fff'
    };

    return (
        <div style={layoutStyle}>
            <div style={contentStyle}>
                <div style={cardStyle}>

                    {/* Show error message if any */}
                    {errorMessage && (
                        <div style={{color: 'red', textAlign: 'center'}}>{errorMessage}</div>
                    )}

                    <form onSubmit={handleLoginSubmit} style={formStyle}>
                        <label style={labelStyle}>
                            Логин
                            <input
                                type="text"
                                style={inputStyle}
                                value={loginValue}
                                onChange={(e) => setLoginValue(e.target.value)}
                                placeholder="Введите логин"
                            />
                        </label>
                        <label style={labelStyle}>
                            Пароль
                            <input
                                type="password"
                                style={inputStyle}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Введите пароль"
                            />
                        </label>
                        <button type="submit" style={buttonStyle}>
                            Войти
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
