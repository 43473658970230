import React, { useState, useEffect } from 'react';

function CookieConsentDialog() {

    const initialConsent = localStorage.getItem('cookieConsent');
    const [isVisible, setIsVisible] = useState(initialConsent === null);
    // Check the localStorage on mount to determine visibility

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (cookieConsent != null) {
            setIsVisible(false); // Hide dialog if consent is already stored
        }
    }, []);

    const handleConsent = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false); // Hide the dialog after consent
    };

    // If consent is already granted or declined, do not render the dialog
    if (!isVisible) return null;

    return (
        <div style={styles.dialog}>
            <h2 style={styles.heading}>Используем куки</h2>
            <p style={styles.text}>
                Это чтобы сайт работал лучше.
                <br/> Оставаясь с нами, вы соглашаетесь на использование
                <a href="/info" style={styles.link} target="_blank" rel="noopener noreferrer"> файлов куки</a>.
            </p>
            <div style={styles.buttons}>
                <button
                    onClick={handleConsent}
                    style={styles.button}
                >
                    ОК
                </button>
            </div>
        </div>
    );
}

// Styles for the dialog
const styles = {
    dialog: {
        zIndex: 9999,  // Adding a high z-index value
        position: 'fixed',
        margin: '20px',  // Padding from the right side
        bottom: '0px',  // Padding from the bottom side
        backgroundColor: '#000',  // Dark background for better contrast
        padding: '20px 30px',
        borderRadius: '6px',
        width: 'auto',
        maxWidth: '360px',  // Restrict the width for better appearance
        textAlign: 'center',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
    },
    heading: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#fff',
        marginBottom: '15px',
    },
    text: {
        fontSize: '16px',
        color: '#fff',
        marginBottom: '25px',
        lineHeight: '1.6',
    },
    link: {
        color: '#fff',  // Blue link color for visibility
        textDecoration: 'none',  // Remove underline from the link
        fontWeight: '600',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',  // Center the button horizontally
        width: '100%',
        marginTop: '8px',
    },
    button: {
        padding: '12px 20px',
        border: 'none',
        borderRadius: '6px',
        fontSize: '16px',
        color: '#000',
        cursor: 'pointer',
        fontWeight: '600',
        transition: 'background-color 0.3s ease',
        backgroundColor: '#fff',  // Blue button background for better visibility
        width: '100%',
    },
};

export default CookieConsentDialog;
