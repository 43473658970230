import React, {useState, useEffect} from 'react';
import api from '../../api';
import {useAppContext} from '../../context/AppContext';

// Helper function to convert numeric status to text.
const getStatusString = (status) => {
    switch (status) {
        case 1:
            return 'Новая анкета';
        case 2:
            return 'Ожидает модерации';
        case 3:
            return 'Анкета активна';
        case 4:
            return 'Анкета отклонена';
        default:
            return 'Неизвестный статус';
    }
};

const getCityName = (cityId) => {
    switch (cityId) {
        case 1:
            return 'Москва';
        case 2:
            return 'Санкт-Петербург';
        default:
            return '';
    }
}

function PrivateTable() {
    const {getValue} = useAppContext();  // to get auth token, etc.

    // The status filter (1..4). Default to 1 or any suitable default
    const [statusValue, setStatusValue] = useState(1);
    // The fetched list of privates
    const [privates, setPrivates] = useState([]);

    // Fetch data whenever statusValue changes
    useEffect(() => {
        const fetchPrivates = async () => {
            try {
                const authToken = getValue('adminAuthToken');
                const response = await api.get(`/admin/privates?status=${statusValue}`, {
                    headers: {Authorization: `Bearer ${authToken}`},
                });
                setPrivates(response.data);
            } catch (error) {
                // console.error('Error fetching privates:', error);
            }
        };

        fetchPrivates();
    }, [statusValue, getValue]);

    // Handler when user selects a different status
    const handleStatusChange = (e) => {
        setStatusValue(Number(e.target.value));
    };

    // When you click a row in the table, navigate to /private/:id
    const handleRowClick = (id) => {
        window.open(
            `/hfwiehfwkuehfw98ef89wehfkwejhfkjwehfkjwehfkjwe/private/${id}`,
            "_blank",
            "noopener,noreferrer"
        );
    };

    return (
        <div style={{padding: '16px'}}>
            {/* Status Filter */}
            <div style={{marginBottom: '16px'}}>
                <label htmlFor="statusFilter">
                    Статус:{' '}
                    <select
                        id="statusFilter"
                        value={statusValue}
                        onChange={handleStatusChange}
                    >
                        <option value={1}>1 — Новые анкеты</option>
                        <option value={2}>2 — Модерация</option>
                        <option value={3}>3 — Активные анкеты</option>
                        <option value={4}>4 — Отклоненные анкеты</option>
                    </select>
                </label>
            </div>

            {/* Table */}
            <table
                style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                }}
            >
                <thead>
                <tr style={{borderBottom: '2px solid #ccc'}}>
                    <th style={{textAlign: 'left', padding: '8px'}}>id</th>
                    <th style={{textAlign: 'left', padding: '8px'}}>имя</th>
                    <th style={{textAlign: 'left', padding: '8px'}}>id города</th>
                    <th style={{textAlign: 'left', padding: '8px'}}>имя города</th>
                    <th style={{textAlign: 'left', padding: '8px'}}>статус</th>
                    <th style={{textAlign: 'left', padding: '8px'}}>модератор</th>
                </tr>
                </thead>
                <tbody>
                {privates.map((item) => (
                    <tr
                        key={item.id}
                        onClick={() => handleRowClick(item.id)}
                        style={{
                            cursor: 'pointer',
                            borderBottom: '1px solid #eee',
                        }}
                    >
                        <td style={{padding: '8px'}}>{item.id}</td>
                        <td style={{padding: '8px'}}>{item.name}</td>
                        <td style={{padding: '8px'}}>{item.cityId}</td>
                        <td style={{padding: '8px'}}>{getCityName(item.cityId)}</td>
                        <td style={{padding: '8px'}}>{getStatusString(item.status)}</td>
                        <td style={{padding: '8px'}}>{item.moderator}</td>
                    </tr>
                ))}
                {privates.length === 0 && (
                    <tr>
                        <td colSpan={3} style={{padding: '8px', textAlign: 'center'}}>
                            Нет данных
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default PrivateTable;
