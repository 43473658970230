import React from 'react';

const Privacy = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ САЙТА В СЕТИ «ИНТЕРНЕТ»</h1>
            <p>г. Москва «01» января 2025 г.</p>

            <p>
                Настоящая Политика конфиденциальности (далее - Политика) является неотъемлемой частью Соглашения об использовании Сайта, Контента и Сервисов Сайта в сети «Интернет» (пользовательское соглашение, оферта), размещенного на сайте в сети Интернет по адресу: <a href="https://marketplace.dating" target="_blank">https://marketplace.dating</a> (далее - Сайт) и знакомясь с настоящей Политикой Пользователь подтверждает ознакомление с Соглашением со всеми приложениями и принятие его условий.
            </p>

            <p>
                Администратор уделяет большое внимание защите личной информации пользователей, при этом, использование Сайта и Сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации, в случае несогласия с этими условиями Пользователь должен воздержаться от использования материалов и сервисов Сайта.
            </p>

            <h2>1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>

            <p>
                Настоящий документ определяет политику Администратора в отношении обработки персональных данных Пользователей. Политика разработана во исполнение требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» в целях принятия мер необходимых и достаточных для обеспечения защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
            </p>

            <h3>1.1. В рамках настоящей Политики используются понятия и термины, установленные Соглашением, а также следующие основные понятия и термины:</h3>

            <ul>
                <li>Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных - Пользователю).</li>
                <li>Оператор персональных данных (оператор) – Администратор, самостоятельно или совместно с другими лицами организующая и (или) осуществляющая обработку персональных данных, а также определяющая цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</li>
                <li>Субъект персональных данных (Пользователь) - дееспособное совершеннолетнее физическое лицо, осуществляющее доступ к Сайту и Сервисам Сайта посредством сети Интернет, а также дееспособное совершеннолетнее физическое лицо, прошедшее регистрацию, создавшее учетную запись/аккаунт, добровольно предоставившее свои персональные данные, информацию и фотографии (контент), Анкету с целью размещения на Сайте для оказания услуг массажа (Модель).</li>
                <li>Обработка персональных данных - любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя, в том числе: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение.</li>
                <li>Автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники.</li>
                <li>Распространение персональных данных - действия, направленные на раскрытие персональных данных неопределенному кругу лиц.</li>
                <li>Предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</li>
                <li>Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</li>
                <li>Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.</li>
                <li>Обезличивание персональных данных - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.</li>
                <li>Информационная система персональных данных - совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</li>
                <li>Трансграничная передача персональных данных - передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.</li>
            </ul>

            <h3>1.2. Настоящая Политика конфиденциальности применяется только к Сайту <a href="https://marketplace.dating" target="_blank">https://marketplace.dating</a>. Сайт <a href="https://marketplace.dating" target="_blank">https://marketplace.dating</a> не контролирует и не несет ответственности за сайты третьих лиц, за самих третьих лиц и размещаемой/предоставляемой ими информации на которые Пользователь может перейти по ссылкам, доступным на Сайте <a href="https://marketplace.dating" target="_blank">https://marketplace.dating</a>.</h3>

            <h3>1.3. Правовые основания обработки Персональных данных:</h3>

            <ul>
                <li>Обработка персональных данных Пользователей основана на Гражданском кодексе Российской Федерации; Налоговом кодексе Российской Федерации; договорах, соглашениях с Субъектами персональных данных, Согласии на обработку персональных данных от Субъектов персональных данных, иных нормативных правовых актах, регулирующих отношения, связанные с деятельностью Администратора, локальных нормативных актах Администратора, размещенных на Сайте.</li>
                <li>Правовым основанием обработки персональных данных также является хотя бы одно из указанных целей/условий:
                    <ul>
                        <li>обработка персональных данных для достижения целей осуществления и выполнения, возложенных законодательством Российской Федерации на Администратора функций, полномочий и обязанностей;</li>
                        <li>обработка персональных данных для исполнения договора, стороной которого либо выгодоприобретателем или поручителем, по которому является Субъект персональных данных, а также для заключения договора по инициативе Субъекта персональных данных или договора, по которому Субъект персональных данных будет являться выгодоприобретателем или поручителем;</li>
                        <li>обработка персональных данных для осуществления прав и законных интересов Администратора или третьих лиц либо достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы Субъекта персональных данных;</li>
                        <li>в статистических или иных исследовательских целях, при условии обязательного обезличивания персональных данных;</li>
                        <li>в иных случаях — на основании согласия на обработку персональных данных.</li>
                    </ul>
                </li>
            </ul>

            <h2>2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</h2>

            <p>
                2.1. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.
                Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Администратор не допускает обработку персональных данных, несовместимую с целями сбора персональных данных.
            </p>

            <p>
                Сайт не обрабатывает информацию о частной жизни Пользователей, а Пользователи гарантируют, что представленные ими информация (Контент) не является предметом частной жизни.
            </p>

            <h3>2.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:</h3>

            <ul>
                <li>Идентификации Пользователя, обратившегося на Сайт или зарегистрированного на Сайте, как совершеннолетнего гражданина, для предоставления доступа к Сервисам Сайта, Контенту (информации и/или материалам), содержащимся на Сайте, для ознакомления, заключения, исполнения и прекращения гражданско-правовых договоров.</li>
                <li>Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</li>
                <li>Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.</li>
                <li>Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</li>
                <li>Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</li>
                <li>Создания учетной записи/аккаунта для размещения Анкеты на Сайте, если Пользователь дал согласие на создание учетной записи/аккаунта.</li>
                <li>Уведомления Пользователя Сайта о новых продуктах и услугах, специальных предложениях и различных событиях.</li>
                <li>Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.</li>
                <li>Осуществления рекламной деятельности с согласия Пользователя.</li>
            </ul>

            <h2>3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</h2>

            <p>
                3.1. Обработка персональных данных осуществляется с согласия Субъекта персональных данных на обработку персональных данных.
                Использование Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональных данных.
            </p>

            <p>
                Согласие может быть отозвано Пользователем или его представителем путем направления Администратору письменного заявления в Telegram аккаунт: <a href="https://t.me/marketplace_18plus" target="_blank">https://t.me/marketplace_18plus</a> и только с этого момента Администратор не уполномочен на обработку персональных данных конкретного пользователя.
            </p>

            <h3>3.2. Администратор, основываясь на презумпции добросовестности Субъекта персональных данных (Пользователя), исходит из того, что при предоставлении персональных данных Пользователь:</h3>

            <ul>
                <li>Является дееспособным, совершеннолетним физическим лицом.</li>
                <li>Указывает достоверную информацию о себе в объемах, необходимых для использования, и поддерживает предоставленные персональные данные в актуальном состоянии.</li>
                <li>Осознает, что информация на Сайте, размещаемая Пользователем о себе, может становиться доступной для других Пользователей Сайта, может быть скопирована и распространена такими Пользователями;</li>
                <li>Осознает, что обработка некоторых персональных данных основывается на требованиях действующего законодательства Российской Федерации, также обработка персональных данных необходима в целях исполнения договора с таким Субъектом персональных данных.</li>
                <li>Ознакомлен с настоящей Политикой, выражает свое информированное и осознанное согласие с ней.</li>
            </ul>

            <h3>3.3. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.</h3>

            <h3>3.4. Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</h3>
            <ul>
                <li>Пользователь выразил согласие на такие действия.</li>
                <li>Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем.</li>
                <li>Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры (например органам дознания и следствия и иным органам исполнительной власти в целях, обусловленных законодательством Российской Федерации. Персональные данные передаются в объеме и в соответствии с требованиями законодательства Российской Федерации).</li>
                <li>В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</li>
            </ul>

            <h3>3.5. Обработка персональных данных Пользователя осуществляется без ограничения срока следующими способами:</h3>
            <ul>
                <li>Сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</li>
                <li>Обработка персональных данных Пользователей осуществляется в соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных".</li>
            </ul>

            <h3>3.6. При утрате или разглашении персональных данных Администратор информирует Пользователя об утрате или разглашении персональных данных.</h3>

            <h3>3.7. Администратор принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</h3>

            <h3>3.8. Администратор совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</h3>

            <h2>4. ОБЯЗАТЕЛЬСТВА СТОРОН</h2>

            <h3>4.1. Пользователь обязан:</h3>
            <ul>
                <li>Являться дееспособным, совершеннолетним физическим лицом.</li>
                <li>Добровольно и самостоятельно предоставить достоверную информацию о персональных данных, необходимую для пользования Сайтом.</li>
                <li>Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной информации.</li>
                <li>Не предоставлять информацию о частной жизни (та область жизнедеятельности человека, которая относится к отдельному лицу, касается только его и не подлежит контролю со стороны общества и государства, если носит непротивоправный характер. Соответственно, лишь само лицо вправе определить, какие именно сведения, имеющие отношение к его частной жизни, должны оставаться в тайне, а потому и сбор, хранение, использование и распространение такой информации, не доверенной никому, не допускается без согласия данного лица, как того требует Конституция Российской Федерации). Предоставляя информацию, Пользователь подтверждает, что предоставляемая информация не содержит тайну частной жизни.</li>
                <li>При каждом случае передачи персональных данных знакомиться с текстом Политики.</li>
            </ul>

            <h3>4.2. Администратор обязан:</h3>
            <ul>
                <li>Использовать полученную информацию исключительно для целей, указанных в настоящей Политике конфиденциальности.</li>
                <li>Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением предусмотренных настоящей Политикой конфиденциальности.</li>
                <li>Для обеспечения защиты персональных данных Пользователя при их обработке принять правовые, организационные и технические меры от несанкционированного, неправомерного или случайного доступа к персональным данным, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.</li>
                <li>Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.</li>
            </ul>

            <h2>5. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>

            <h3>5.1. В случае утраты или разглашения конфиденциальной информации Администратор не несет ответственности, если данная конфиденциальная информация:</h3>
            <ul>
                <li>Стала публичным достоянием до ее утраты или разглашения.</li>
                <li>Была получена от третьей стороны до момента ее получения Администратором.</li>
                <li>Была разглашена с согласия Пользователя.</li>
                <li>Была собрана сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг.</li>
            </ul>

            <h3>5.2. Пользователь, передавший Администратору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, самостоятельно несет всю полноту ответственности, предусмотренной законодательством Российской Федерации, а Администратор и Сайт освобождаются от ответственности.</h3>

            <h2>6. РАЗРЕШЕНИЕ СПОРОВ</h2>

            <h3>6.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Администратором, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</h3>

            <h3>6.2. Получатель претензии в течение 10 календарных дней со дня получения претензии письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</h3>

            <h3>6.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим законодательством Российской Федерации.</h3>

            <h3>6.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администратором применяется действующее законодательство Российской Федерации.</h3>

            <h2>7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>

            <h3>7.1. Администратор вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</h3>

            <h3>7.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</h3>

            <h3>7.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать в Telegram аккаунт: <a href="https://t.me/marketplace_18plus" target="_blank">https://t.me/marketplace_18plus</a>.</h3>

            <h3>7.4. Действующая Политика конфиденциальности размещена на странице по адресу: <a href="https://marketplace.dating" target="_blank">https://marketplace.dating</a>.</h3>

            <h3>7.5. Настоящая Политика конфиденциальности является неотъемлемой частью Соглашения об использовании материалов и сервисов интернет-сайта (пользовательское соглашение, оферта), размещенного на странице по адресу: <a href="https://marketplace.dating" target="_blank">https://marketplace.dating</a>.</h3>

            <h3>7.6. Администратор до начала осуществления деятельности по трансграничной передаче персональных данных обязан уведомить уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять трансграничную передачу персональных данных (такое уведомление направляется отдельно от уведомления о намерении осуществлять обработку персональных данных). До подачи вышеуказанного уведомления, Администратор обязан получить от органов власти иностранного государства, иностранных физических лиц, иностранных юридических лиц, которым планируется трансграничная передача персональных данных, соответствующие сведения.</h3>
        </div>

    );
};

export default Privacy;
