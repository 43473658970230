import React, {useEffect, useState} from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import FilterDialog from './FilterDialog';
import {useAppContext} from '../context/AppContext';

function Header({shouldAnimate = false}) {
    const {city: currentCity} = useParams(); // "msk" or "spb"
    const navigate = useNavigate();

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedCity, setSelectedCity] = useState(''); // Default

    const {setValue} = useAppContext();

    const [isOnTop, setIsOnTop] = useState(window.scrollY === 0 && shouldAnimate);

    useEffect(() => {
        // Listener checks scroll position and updates state
        function handleScroll() {
            setIsOnTop(window.scrollY === 0 && shouldAnimate);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Map from city names to slugs
    const citySlugMap = {
        'Москва': 'msk',
        'Санкт-Петербург': 'spb'
    };

    // Reverse map: from slug to city name
    let slugCityMap;
    slugCityMap = {
        msk: 'Москва',
        spb: 'Санкт-Петербург'
    };

    // On mount or whenever currentCity changes, update the selectedCity accordingly.
    useEffect(() => {
        if (!currentCity) return;
        // If "spb", then selectedCity = "Санкт-Петербург", else "Москва"
        const cityName = slugCityMap[currentCity] || 'Москва';
        setSelectedCity(cityName);
    }, [currentCity]);

    const handleFilterToggle = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // When user selects a city, update state and navigate to the corresponding slug (msk or spb).
    const handleCitySelect = (cityName) => {
        setSelectedCity(cityName);
        const slug = citySlugMap[cityName] || 'msk';

        setValue('lastSelectedCity', slug);
        navigate(`/${slug}`);
    };

    // When clicking "marketplace", either go to the city in localStorage or fallback to currentCity or 'msk'.
    const handleBrandClick = () => {
        navigate(`/`);
    };

    // --- СТИЛИ (изменили только то, что нужно) ---
    const headerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 24px',
        transition: 'background-color 0.3s ease, border-bottom 0.3s ease',
        backgroundColor: isOnTop ? 'transparent' : 'rgba(255, 255, 255, 0.85)',
        borderBottom: isOnTop ? 'transparent' : '1px solid #ccc',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
    };

    const leftSectionStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '24px'
    };

    const brandStyle = {
        margin: 0,
        fontSize: '1.5rem',
        fontWeight: '600',
        transition: 'color 0.3s ease',
        color: isOnTop ? '#fff' : '#000',
    };

    const cityContainerStyle = {
        display: 'flex',
        gap: '8px',
        alignItems: 'center'
    };

    const cityButtonWideStyle = (cityName) => ({
        padding: '6px 0px',
        cursor: 'pointer',
        fontSize: '0.85rem',
        textDecoration: 'none',
        position: 'relative',
        transition: 'color 0.3s ease',
        fontWeight: cityName === selectedCity ? '500' : 'normal',
        color: cityName === selectedCity ? (isOnTop ? '#fff' : '#000') : (isOnTop ? '#fff' : '#555'),
    });

    const menuItemsStyle = {
        display: 'flex',
        gap: '24px',
        alignItems: 'center'
    };

    const menuLinkStyle = {
        padding: '6px 0px',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
        color: isOnTop ? '#fff' : '#000',
        fontSize: '0.9rem',
        position: 'relative',
        display: 'inline-block'
    };

    const hamburgerStyle = {
        fontSize: '1.5rem',
        cursor: 'pointer',
        display: 'none', // Shown via media query
        transition: 'color 0.3s ease',
        color: isOnTop ? '#fff' : '#000',
    };

    const sideMenuStyle = {
        position: 'absolute',
        top: '64px',
        right: '24px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        padding: '16px',
        zIndex: 1000,
        display: isMenuOpen ? 'block' : 'none',
    };

    const sideCityLinkStyle = (cityName) => ({
        textDecoration: 'none',
        color: '#555',
        fontSize: '0.9rem',
        display: 'block',
        marginBottom: '12px',
        borderBottom: cityName === selectedCity ? '1px solid #555' : 'none',
        paddingBottom: '4px'
    });

    const sideMenuLinkStyle = {
        textDecoration: 'none',
        color: '#555',
        fontSize: '0.9rem',
        display: 'block',
        marginBottom: '12px',
        transition: 'color 0.3s'
    };

    const cityContainerHoverColor = isOnTop ? '#fff' : '#000';

    return (
        <header style={headerStyle}>
            <div style={leftSectionStyle}>
                {/* The brand link uses an onClick to handle custom logic */}
                <span
                    onClick={handleBrandClick}
                    style={{textDecoration: 'none', color: '#000', cursor: 'pointer'}}
                >
                    <h1 style={brandStyle}>marketplace</h1>
                </span>

                <div className="cityContainer" style={cityContainerStyle}>
                    <span
                        style={cityButtonWideStyle('Москва')}
                        onClick={() => handleCitySelect('Москва')}
                    >
                        Москва
                    </span>
                    <span
                        style={cityButtonWideStyle('Санкт-Петербург')}
                        onClick={() => handleCitySelect('Санкт-Петербург')}
                    >
                        Санкт-Петербург
                    </span>
                </div>
            </div>

            <div className="menuItems" style={menuItemsStyle}>
                <Link
                    to={`/${currentCity || 'msk'}/map`}
                    className="menuLink"
                    style={menuLinkStyle}
                >
                    Карта
                </Link>
                <span
                    onClick={handleFilterToggle}
                    className="menuLink"
                    data-umami-event="Filters Clicked"
                    style={{...menuLinkStyle, cursor: 'pointer'}}
                >
                    Фильтры
                </span>
                {/* Login without city prefix */}
                <Link to="/login" className="menuLink" style={menuLinkStyle}>
                    Вход для моделей
                </Link>
            </div>

            <div className="hamburgerMenu" style={hamburgerStyle} onClick={handleMenuToggle}>
                ☰
            </div>

            {/* Side menu */}
            <div className="sideMenu" style={sideMenuStyle}>
                <span
                    style={sideCityLinkStyle('Москва')}
                    onClick={() => {
                        handleCitySelect('Москва');
                        setIsMenuOpen(false);
                    }}
                >
                    Москва
                </span>
                <span
                    style={sideCityLinkStyle('Санкт-Петербург')}
                    onClick={() => {
                        handleCitySelect('Санкт-Петербург');
                        setIsMenuOpen(false);
                    }}
                >
                    Санкт-Петербург
                </span>
                <div style={{marginBottom: '24px'}}></div>
                <Link
                    to={`/${currentCity || 'msk'}/map`}
                    style={sideMenuLinkStyle}
                    onClick={() => setIsMenuOpen(false)}
                >
                    Карта
                </Link>
                <span
                    onClick={() => {
                        handleFilterToggle();
                        setIsMenuOpen(false);
                    }}
                    data-umami-event="Filters Clicked"
                    style={{...sideMenuLinkStyle, cursor: 'pointer'}}
                >
                    Фильтры
                </span>
                <Link
                    to="/login"
                    style={sideMenuLinkStyle}
                    onClick={() => setIsMenuOpen(false)}
                >
                    Вход для моделей
                </Link>
                <div style={{marginBottom: '24px'}}></div>
                <a
                    href="/info"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={sideMenuLinkStyle}
                >
                    Документы
                </a>
                <a
                    href="https://t.me/marketplace_18plus"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={sideMenuLinkStyle}
                >
                    Telegram Support
                </a>
            </div>

            {isFilterOpen && <FilterDialog onClose={handleFilterToggle}/>}

            <style>{`
                .menuLink::after,
                .cityContainer a::after,
                .cityContainer span::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    transition: width 0.3s ease, color 0.3s ease;
                    background-color: ${cityContainerHoverColor};
                }

                .menuLink:hover::after,
                .cityContainer a:hover::after,
                .cityContainer span:hover::after {
                    width: 100%;
                }

                .menuLink:hover,
                .cityContainer a:hover,
                .cityContainer span:hover {
                    color: #000;
                }

                .cityContainer a,
                .cityContainer span {
                    position: relative;
                    border-bottom: none;
                }

                .cityContainer a:hover,
                .cityContainer span:hover {
                    border-bottom: none;
                }

                .cityContainer a[style*="border-bottom"]::after,
                .cityContainer span[style*="border-bottom"]::after {
                    width: 100%;
                }

                /* On screens wider than 800px */
                @media (min-width: 801px) {
                    .menuItems {
                        display: flex !important;
                    }
                    .cityContainer {
                        display: flex !important;
                    }
                    .hamburgerMenu {
                        display: none !important;
                    }
                    .sideMenu {
                        display: none !important;
                    }
                }

                /* On screens 800px and below */
                @media (max-width: 800px) {
                    .menuItems {
                        display: none !important;
                    }
                    .cityContainer {
                        display: none !important;
                    }
                    .hamburgerMenu {
                        display: block !important;
                    }
                }
            `}</style>
        </header>
    );
}

export default Header;
