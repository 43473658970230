// File: src/components/InfoCircle.js
import React, { useState } from 'react';
import api from "../api";

const InfoCircle = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState(''); // Track the textarea value

    const togglePopup = () => setIsOpen(!isOpen);

    const handleSubmit = async () => {
        try {
            await api.post('/feedback', { message: text });
        } catch (err) {
            //
        } finally {
            setIsOpen(false); // Close the popup after submission
            setText(''); // Clear the textarea after submission
        }
    };

    const handleChange = (e) => {
        setText(e.target.value); // Update the textarea value
    };

    return (
        <>
            <div
                className={`info-circle ${isOpen ? 'open' : ''}`}
                onClick={togglePopup}
                data-umami-event="Feedback Clicked"
            >
                i
            </div>

            {isOpen && (
                <div className="popup-overlay">
                    <div className="popup">
                        <div className="popup-header">
                            <span className="exit-cross" onClick={togglePopup}>×</span>
                        </div>
                        <h2>Отзыв</h2>
                        <textarea
                            placeholder="Ваш отзыв..."
                            value={text}
                            onChange={handleChange}
                        />
                        <button
                            onClick={handleSubmit}
                            disabled={!text}
                            data-umami-event="Submit Feedback Clicked"
                        >Отправить</button>
                    </div>
                </div>
            )}

            <style>
                {`
          .info-circle {
            position: fixed;
            bottom: 20px;
            right: 20px;
            background-color: black;
            color: white;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 24px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          }

          .popup-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
          }

          .popup {
            background-color: white;
            color: black;
            padding: 20px;
            border-radius: 8px;
            width: 90%;
            max-width: 500px;
            text-align: center;
            position: relative;
          }

          .popup-header {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 10px 20px;
          }

          .exit-cross {
            font-size: 30px;
            cursor: pointer;
            color: black;
          }

          .popup h2 {
            font-size: 24px;
            margin: 20px 0;
          }

          .popup textarea {
            width: 80%;
            height: 120px;
            margin: 10px 0;
            padding: 10px;
            border: 1px solid black;
            border-radius: 4px;
            background-color: #f9f9f9;
            color: black;
            font-size: 16px;
            resize: none;
          }

          .popup button {
            background-color: black;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 6px;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
          }

          .popup button:hover {
            background-color: #333;
          }

          .popup button:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }

          /* Responsive design for mobile */
          @media (max-width: 768px) {
            .info-circle {
              width: 40px;
              height: 40px;
              font-size: 20px;
            }

            .popup {
              padding: 15px;
              width: 90%;
              max-width: 400px;
            }

            .popup textarea {
              height: 120px;
            }
          }
        `}
            </style>
        </>
    );
};

export default InfoCircle;
