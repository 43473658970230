// File: src/pages/ModelDetails.js
import React, {useRef, useEffect, useState, useCallback} from "react";
import {useParams} from "react-router-dom";
import api from "../api"; // <-- Make sure this path is correct, e.g. "../api/index.js"
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const API_KEY_YANDEX_MAPS = process.env.REACT_APP_API_KEY_YANDEX_MAPS;

// Helpers for age suffix
function getAgeSuffix(age) {
    const lastDigit = age % 10;
    const lastTwo = age % 100;
    if (lastTwo >= 11 && lastTwo <= 14) return 'лет';
    if (lastDigit === 1) return 'год';
    if (lastDigit >= 2 && lastDigit <= 4) return 'года';
    return 'лет';
}

function calculateAge(birthDateString) {
    if (!birthDateString) return null;
    const birthDate = new Date(birthDateString);
    const now = new Date();
    let age = now.getFullYear() - birthDate.getFullYear();
    const m = now.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

const ModelDetails = () => {
    const {id} = useParams();

    // 1) All Hooks unconditionally at top
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 820);

    const [model, setModel] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    // Gallery states
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

    // Refs for desktop parallax
    const photoContainerRef = useRef(null);
    const descriptionContainerRef = useRef(null);

    // 2) Effects and callbacks

    // Fetch model data
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await api.get(`/private/${id}`);
                setModel(response.data);
                setLoading(false);
            } catch (err) {
                setError("Ошибка при загрузке данных");
                setLoading(false);
            }
        }

        fetchData();
    }, [id]);

    // Window resize -> detect mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 820);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Desktop parallax
    useEffect(() => {
        if (!isMobileView && model) {
            const photoElem = photoContainerRef.current;
            const descElem = descriptionContainerRef.current;
            if (!photoElem || !descElem) return;

            // 1) Wait for images to load
            const images = photoElem.querySelectorAll("img");
            let loadedCount = 0;

            function onImgLoad() {
                loadedCount++;
                if (loadedCount === images.length) {
                    // 2) All images are loaded; now start parallax logic
                    initParallax(photoElem, descElem);
                }
            }

            images.forEach((img) => {
                if (img.complete) {
                    loadedCount++;
                } else {
                    img.addEventListener("load", onImgLoad, {once: true});
                }
            });

            // If they were all already complete:
            if (loadedCount === images.length) {
                initParallax(photoElem, descElem);
            }
        }

        function initParallax(photoElem, descElem) {
            window.scrollTo(0, 0);

            let currentPhotoY = 0;
            let currentDescY = 0;
            let targetPhotoY = 0;
            let targetDescY = 0;
            const lerpSpeed = 0.05;

            const handleScroll = () => {
                const scrollY = window.scrollY;
                const pageHeight =
                    document.documentElement.scrollHeight - window.innerHeight;

                const photoHeight = photoElem.scrollHeight - window.innerHeight;
                const descHeight = descElem.scrollHeight - window.innerHeight;

                targetPhotoY = Math.min((scrollY / pageHeight) * photoHeight, photoHeight);
                targetDescY = Math.min((scrollY / pageHeight) * descHeight, descHeight);
            };

            const animate = () => {
                currentPhotoY += (targetPhotoY - currentPhotoY) * lerpSpeed;
                currentDescY += (targetDescY - currentDescY) * lerpSpeed;

                photoElem.style.transform = `translateY(-${currentPhotoY}px)`;
                descElem.style.transform = `translateY(-${currentDescY}px)`;
                requestAnimationFrame(animate);
            };

            window.addEventListener("scroll", handleScroll, {passive: true});
            requestAnimationFrame(animate);

            // Cleanup logic if needed...
        }
    }, [isMobileView, model]);

    // Gallery callbacks
    const openGallery = useCallback((index) => {
        setCurrentPhotoIndex(index);
        setIsGalleryOpen(true);
    }, []);
    const closeGallery = useCallback(() => setIsGalleryOpen(false), []);
    const prevPhoto = useCallback(() => {
        if (!model || !model.photos) return;
        setCurrentPhotoIndex((prev) =>
            prev === 0 ? model.photos.length - 1 : prev - 1
        );
    }, [model]);
    const nextPhoto = useCallback(() => {
        if (!model || !model.photos) return;
        setCurrentPhotoIndex((prev) =>
            prev === model.photos.length - 1 ? 0 : prev + 1
        );
    }, [model]);

    const handlePhoneClick = () => {
        const isMobile = /iphone|ipad|ipod|android/i.test(navigator.userAgent);

        if (model.phone) {
            if (isMobile) {
                window.location.href = `tel:${model.phone}`;
            } else {
                if (navigator.clipboard?.writeText) {
                    navigator.clipboard.writeText(model.phone)
                        .then(() => alert(`${model.phone} скопирован в буфер обмена`))
                        .catch(() => alert('Не удалось скопировать номер'));
                } else {
                    const textarea = document.createElement('textarea');
                    textarea.value = model.phone;
                    document.body.appendChild(textarea);
                    textarea.select();
                    try {
                        const success = document.execCommand('copy');
                        alert(success ? `${model.phone} скопирован в буфер обмена` : 'Не удалось скопировать номер');
                    } catch (err) {
                        alert('Не удалось скопировать номер');
                    }
                    document.body.removeChild(textarea);
                }
            }
        }
    };

    const handleWhatsAppClick = () => {
        if (model.whatsApp) {
            window.open(
                `https://wa.me/${model.whatsApp}?text=Здравствуйте, ${model.name}, нашел ваш номер на сайте Marketplace.`,
                '_blank'
            );
        }
    };

    const handleTelegramClick = () => {
        if (!model.telegram) return;

        const username = model.telegram.replace('@', '');
        const appLink = `tg://resolve?domain=${username}`;
        const webLink = `https://t.me/${username}`;

        // Check if user agent is on a mobile device
        const isMobile = /iphone|ipad|ipod|android/i.test(navigator.userAgent);

        if (isMobile) {
            window.open(webLink, '_blank');
            setTimeout(() => {
                window.location.href = appLink;
            }, 1000);

        } else {
            // On desktop or non-mobile devices, just open the Telegram web link
            window.open(webLink, '_blank');
        }
    };

    // Keyboard for gallery
    useEffect(() => {
        if (isGalleryOpen) {
            const handleKeyDown = (e) => {
                if (e.key === "ArrowLeft") prevPhoto();
                else if (e.key === "ArrowRight") nextPhoto();
                else if (e.key === "Escape") closeGallery();
            };
            window.addEventListener("keydown", handleKeyDown);
            return () => window.removeEventListener("keydown", handleKeyDown);
        }
    }, [isGalleryOpen, closeGallery, prevPhoto, nextPhoto]);

    // 3) We do a single final return, but handle loading/error inside the UI
    // so we keep Hooks unconditional

    // Common style
    const buttonStyle = {
        padding: "10px 20px",
        fontSize: "1rem",
        fontWeight: "600",
        border: "none",
        borderRadius: "6px",
        backgroundColor: "#000",
        color: "#ffffff",
        cursor: "pointer",
        whiteSpace: "nowrap",
        marginTop: "8px",
    };

    const sharedStyles = {
        tagItem: {
            fontSize: "0.95rem",
            padding: "6px 12px",
            backgroundColor: "#f0f0f0",
            color: "#333333",
            fontWeight: "500",
            borderRadius: "6px",
        },
        modalOverlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
        },
        modalImageContainer: {
            position: "relative",
            width: "85%",
            maxWidth: "900px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        modalImage: {
            width: "100%",
            height: "auto",
            objectFit: "contain",
            maxHeight: "85vh",
        },
        closeButton: {
            position: "fixed",
            top: "16px",
            right: "16px",
            backgroundColor: "transparent",
            border: "none",
            color: "#ffffff",
            fontSize: "2rem",
            cursor: "pointer",
        },
        navButtonLeft: {
            position: "absolute",
            left: "8px",
            backgroundColor: "transparent",
            border: "none",
            color: "#ffffff",
            fontSize: "2.8rem",
            cursor: "pointer",

            outline: 'none', // Remove outline on click/long press
            background: 'transparent', // Remove background effect on click
            WebkitTapHighlightColor: 'transparent', // Remove tap highlight on mobile
            boxShadow: 'none', // Remove any shadow on press
            userSelect: 'none', // Prevent text selection on the arrow symbols
        },
        navButtonRight: {
            position: "absolute",
            right: "8px",
            backgroundColor: "transparent",
            border: "none",
            color: "#ffffff",
            fontSize: "2.8rem",
            cursor: "pointer",

            outline: 'none', // Remove outline on click/long press
            background: 'transparent', // Remove background effect on click
            WebkitTapHighlightColor: 'transparent', // Remove tap highlight on mobile
            boxShadow: 'none', // Remove any shadow on press
            userSelect: 'none', // Prevent text selection on the arrow symbols
        },
    };

    // The Gallery modal
    const GalleryModal = () => {
        if (!isGalleryOpen) return null;
        const handleOverlayClick = () => closeGallery();
        const stopPropagation = (e) => e.stopPropagation();

        return (
            <div style={sharedStyles.modalOverlay} onClick={handleOverlayClick}>
                <div style={sharedStyles.modalImageContainer} onClick={stopPropagation}>
                    <button
                        style={sharedStyles.navButtonLeft}
                        onClick={prevPhoto}
                        onTouchStart={(e) => e.preventDefault()} // Prevent long press effect
                        onMouseDown={(e) => e.preventDefault()} // Prevent mouse down effect
                    >
                        ‹
                    </button>

                    <img
                        src={model.photos[currentPhotoIndex].path}
                        alt={model.name}
                        title={model.name}
                        style={sharedStyles.modalImage}
                    />

                    <button
                        style={sharedStyles.navButtonRight}
                        onClick={nextPhoto}
                        onTouchStart={(e) => e.preventDefault()} // Prevent long press effect
                        onMouseDown={(e) => e.preventDefault()} // Prevent mouse down effect
                    >
                        ›
                    </button>
                    <button style={sharedStyles.closeButton} onClick={closeGallery}>
                        ×
                    </button>
                </div>
            </div>
        );
    };

    // Render final UI
    // If loading or error or !model, show them inline
    if (loading || error || !model) {
        return (
            <div style={{padding: '24px', fontFamily: 'sans-serif', height: "100vh",}}>
                {loading}
                {error && <div style={{color: 'red'}}>{error}</div>}
                {!loading && !error && !model && "Нет данных модели"}
            </div>
        );
    }

    // Now we definitely have model, photos, etc.
    const photosArr = model.photos.map((p) => p.path);
    const ageNum = calculateAge(model.birthDate);
    const ageString = `${ageNum} ${getAgeSuffix(ageNum)}`;
    const modelTags = [
        {label: "Бюст", value: model.bust},
        {label: "Рост", value: model.height},
        {label: "Вес", value: model.weight},
        {label: "Возраст", value: ageNum},
    ].filter(tag => tag.value !== null && tag.value !== undefined && tag.value !== '');

    const finalPrice = model.price;
    const finalName = model.name;
    const descriptionText = model.description || "";
    const renderDescriptionText = descriptionText.split("\n").map((line, idx) => (
        <p key={idx} style={{margin: "0 0 16px 0"}}>
            {line}
        </p>
    ));

    // MOBILE LAYOUT
    if (isMobileView) {
        const mobileStyles = {
            container: {
                maxWidth: "1200px",
                margin: "0 auto",
                padding: "16px",
                backgroundColor: "#fafafa",
            },
            mainPhoto: {
                display: "block",
                margin: "0 auto",
                width: "auto",
                maxWidth: "100%",
                height: "auto",
                minHeight: "360px",
                objectFit: "contain",
                marginBottom: "16px",
                maxHeight: "420px",
                cursor: "pointer",
                outline: 'none',
                userSelect: "none", // Prevent text selection
                WebkitUserDrag: "none", // Prevent image dragging
                WebkitTapHighlightColor: "transparent", // Prevent tap highlight on mobile
            },
            descriptionArea: {
                padding: "16px",
                backgroundColor: "#ffffff",
                borderRadius: "0",
                textAlign: "left",
                marginBottom: "24px",
                fontSize: "1rem",
                color: "#333333",
            },
            tagContainer: {
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                margin: "16px 0",
            },
            buttonRow: {
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                marginTop: "12px",
            },
            carouselWrapper: {
                marginLeft: "-16px",
                marginRight: "-16px",
            },
            carousel: {
                display: "flex",
                overflowX: "auto",
                gap: "10px",
                padding: "0",
                margin: "0",
                alignItems: "center",
            },
            carouselImage: {
                flexShrink: 0,
                height: "320px",
                width: "auto",
                objectFit: "contain",
            },
            mapWrapper: {
                marginLeft: "-16px",
                marginRight: "-16px",
            },
            mapFrame: {
                width: "100%",
                height: "320px",
                border: 0,
                marginTop: "16px",
            },
            headerStyle: {
                fontSize: "1.3rem",
                marginBottom: "12px",
                fontWeight: "600",
                color: "#111111",
            },
            priceStyle: {
                fontSize: "1.4rem",
                marginTop: "16px",
                fontWeight: "600",
                color: "#111111",
            },
            descriptionText: {
                lineHeight: "1.6",
            },
        };

        return (
            <div style={mobileStyles.container}>
                {/* Main photo */}
                <img
                    src={photosArr[0]}
                    alt={model.name}
                    title={model.name}
                    style={mobileStyles.mainPhoto}
                    onClick={() => openGallery(0)}
                />

                {/* Description */}
                <div style={mobileStyles.descriptionArea}>
                    <h1 style={mobileStyles.headerStyle}>{finalName}</h1>
                    <div style={mobileStyles.tagContainer}>
                        {modelTags.map((tag, index) => (
                            <div key={index} style={sharedStyles.tagItem}>
                                {tag.label}: {tag.value}
                            </div>
                        ))}
                    </div>
                    <div style={mobileStyles.descriptionText}>{renderDescriptionText}</div>
                    <h2 style={mobileStyles.priceStyle}>от {finalPrice}₽</h2>
                    <div style={mobileStyles.buttonRow}>
                        {model.phone && (
                            <button
                                style={buttonStyle}
                                onClick={handlePhoneClick}
                                data-umami-event="Phone Clicked"
                                data-umami-event-model-id={model.id}
                            >
                                Телефон
                            </button>
                        )}
                        {model.whatsApp && (
                            <button
                                style={buttonStyle}
                                onClick={handleWhatsAppClick}
                                data-umami-event="WhatsApp Clicked"
                                data-umami-event-model-id={model.id}
                            >
                                WhatsApp
                            </button>
                        )}
                        {model.telegram && (
                            <button
                                style={buttonStyle}
                                onClick={handleTelegramClick}
                                data-umami-event="Telegram Clicked"
                                data-umami-event-model-id={model.id}
                            >
                                Telegram
                            </button>
                        )}
                    </div>
                </div>

                {/* Additional photos carousel */}
                <div style={mobileStyles.carouselWrapper}>
                    <div style={mobileStyles.carousel}>
                        {photosArr.slice(1).map((source, index) => (
                            <img
                                key={index}
                                src={source}
                                alt={model.name}
                                title={model.name}
                                style={mobileStyles.carouselImage}
                                onClick={() => openGallery(index + 1)}
                            />
                        ))}
                    </div>
                </div>

                {/* Map with lat/long */}
                <div style={mobileStyles.mapWrapper}>
                    <YMaps query={{ apikey: API_KEY_YANDEX_MAPS }}>
                        <div style={mobileStyles.mapFrame}>
                            <Map
                                defaultState={{
                                    center: [model.latitude, model.longitude],
                                    zoom: 13
                                }}
                                width="100%"
                                height="100%"
                            >
                                <Placemark
                                    geometry={[model.latitude, model.longitude]}
                                    options={{
                                        iconColor: '#000',
                                    }}
                                />
                            </Map>
                        </div>
                    </YMaps>
                </div>
                {/* Gallery Modal */}
                {isGalleryOpen && <GalleryModal/>}
            </div>
        );
    }

    // DESKTOP LAYOUT
    const desktopStyles = {
        container: {
            display: "flex",
            maxWidth: "1200px",
            margin: "0 auto",
            height: "100vh",
            overflow: "hidden",
            position: "relative",
            // fontFamily: "Arial, sans-serif",
            gap: "12px",
        },
        photoColumn: {
            flex: "1",
            position: "relative",
            overflow: "visible",
            willChange: "transform",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        descriptionColumn: {
            flex: "1",
            padding: "24px",
            position: "relative",
            overflow: "visible",
            willChange: "transform",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            fontSize: "1rem",
            color: "#222222",
            lineHeight: "1.6",
            backgroundColor: "#ffffff",
        },
        photoImage: {
            width: "95%",
            marginBottom: "16px",
            cursor: "pointer",
            maxWidth: "500px",
            objectFit: "contain", // no cropping
            height: "auto",
            borderRadius: "0",
        },
        overlayContainer: {
            position: "relative",
            width: "95%",
            marginBottom: "16px",
            maxWidth: "500px",
            cursor: "pointer",
        },
        overlayImage: {
            width: "100%",
            objectFit: "contain",
            height: "auto",
            borderRadius: "0",
        },
        overlayText: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
            fontSize: "1rem",
        },
        mapFrame: {
            width: "100%",
            height: "360px",
            minHeight: "360px",
            border: 0,
            borderRadius: "6px",
            marginTop: "24px",
        },
        headerStyle: {
            fontSize: "1.5rem",
            marginBottom: "12px",
            fontWeight: "700",
            color: "#111111",
        },
        priceStyle: {
            fontSize: "1.4rem",
            marginTop: "16px",
            fontWeight: "600",
            color: "#111111",
        },
        tagsWrapper: {
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            margin: "16px 0",
        },
        buttonRow: {
            display: "flex",
            gap: "12px",
            marginTop: "16px",
            flexWrap: "wrap",
        },
        descriptionStyle: {
            marginTop: "16px",
        },
    };

    const maxVisiblePhotos = 4;
    const extraPhotosCount = photosArr.length - (maxVisiblePhotos - 1);

    return (
        <div style={desktopStyles.container}>
            {/* Photo side (parallax) */}
            <div style={desktopStyles.photoColumn} ref={photoContainerRef}>
                {photosArr.slice(0, maxVisiblePhotos - 1).map((source, index) => (
                    <img
                        key={index}
                        src={source}
                        alt={model.name}
                        title={model.name}
                        style={desktopStyles.photoImage}
                        onClick={() => openGallery(index)}
                    />
                ))}

                {photosArr.length > maxVisiblePhotos && (
                    <div
                        style={desktopStyles.overlayContainer}
                        onClick={() => openGallery(maxVisiblePhotos - 1)}
                    >
                        <img
                            src={photosArr[maxVisiblePhotos - 1]}
                            alt={model.name}
                            title={model.name}
                            style={desktopStyles.overlayImage}
                        />
                        <div style={desktopStyles.overlayText}>
                            + {extraPhotosCount} фото
                        </div>
                    </div>
                )}
            </div>

            {/* Description side (parallax) */}
            <div style={desktopStyles.descriptionColumn} ref={descriptionContainerRef}>
                <h1 style={desktopStyles.headerStyle}>{finalName}</h1>

                <div style={desktopStyles.tagsWrapper}>
                    {modelTags.map((tag, index) => (
                        <div key={index} style={sharedStyles.tagItem}>
                            {tag.label}: {tag.value}
                        </div>
                    ))}
                </div>

                <div style={desktopStyles.descriptionStyle}>
                    {renderDescriptionText}
                </div>

                <h2 style={desktopStyles.priceStyle}>от {finalPrice}₽</h2>

                <div style={desktopStyles.buttonRow}>
                    {model.phone && (
                        <button
                            style={buttonStyle}
                            onClick={handlePhoneClick}
                            data-umami-event="Phone Clicked"
                            data-umami-event-model-id={model.id}
                        >
                            Телефон
                        </button>
                    )}
                    {model.whatsApp && (
                        <button
                            style={buttonStyle}
                            onClick={handleWhatsAppClick}
                            data-umami-event="WhatsApp Clicked"
                            data-umami-event-model-id={model.id}
                        >
                            WhatsApp
                        </button>
                    )}
                    {model.telegram && (
                        <button
                            style={buttonStyle}
                            onClick={handleTelegramClick}
                            data-umami-event="Telegram Clicked"
                            data-umami-event-model-id={model.id}
                        >
                            Telegram
                        </button>
                    )}
                </div>

                <YMaps query={{ apikey: API_KEY_YANDEX_MAPS }}>
                    <div style={desktopStyles.mapFrame}>
                        <Map
                            defaultState={{
                                center: [model.latitude, model.longitude],
                                zoom: 13
                            }}
                            width="100%"
                            height="100%"
                        >
                            <Placemark
                                geometry={[model.latitude, model.longitude]}
                                options={{
                                    iconColor: '#000',
                                }}
                            />
                        </Map>
                    </div>
                </YMaps>
            </div>

            {/* Gallery Modal */}
            {isGalleryOpen && <GalleryModal/>}
        </div>
    );
};

export default ModelDetails;
