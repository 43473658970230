import React from "react";

function Footer() {
    const footerStyle = {
        fontSize: "0.9rem",
        padding: "20px",
        backgroundColor: "#222", // Darker background for contrast
        color: "#fff",
        marginTop: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "relative",
    };

    const footerLogoImgStyle = {
        width: "50px", // Logo size fixed to 50x50px
        height: "50px", // Ensure it stays square
        objectFit: "contain", // Prevent stretching and distortion of the logo
    };

    const footerBottomStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column", // Stack vertically by default for small screens
        width: "100%",
        marginTop: "20px", // Space between logo and footer content
        textAlign: "center",
    };

    const footerLinksStyle = {
        display: "flex",
        justifyContent: "space-between", // Align Docs, Copyright, and Telegram in one line
        width: "100%",
    };

    const footerLinkStyle = {
        color: "#fff",
        textDecoration: "none",
        fontWeight: "normal",
        fontSize: "0.85rem",
        letterSpacing: "0.5px",
        transition: "opacity 0.3s ease",
        padding: "5px 0",
    };

    const footerLinkHoverStyle = {
        opacity: 0.7,
    };

    return (
        <footer style={footerStyle}>
            {/* Logo section */}
            <img
                src="/logo192.png"
                alt="Marketplace Logo"
                style={footerLogoImgStyle}
            />

            {/* Footer Links: Docs, Copyright, and Telegram */}
            <div style={footerBottomStyle}>
                <div style={footerLinksStyle}>
                    {/* Docs Link (Left aligned) */}
                    <div style={{ textAlign: "left", flex: "1" }} className="footer-docs">
                        <a
                            href="/info"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={footerLinkStyle}
                            onMouseOver={(e) => e.target.style.opacity = footerLinkHoverStyle.opacity}
                            onMouseOut={(e) => e.target.style.opacity = 1}
                        >
                            Документы
                        </a>
                    </div>

                    {/* Copyright (Centered) */}
                    <div style={{ textAlign: "center", flex: "1" }}>
                        <strong>© 2025 Marketplace 18 Plus</strong>
                    </div>

                    {/* Telegram Support Link (Right aligned) */}
                    <div style={{ textAlign: "right", flex: "1" }} className="footer-telegram">
                        <a
                            href="https://t.me/marketplace_18plus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={footerLinkStyle}
                            onMouseOver={(e) => e.target.style.opacity = footerLinkHoverStyle.opacity}
                            onMouseOut={(e) => e.target.style.opacity = 1}
                        >
                            Telegram Support
                        </a>
                    </div>
                </div>
            </div>

            {/* Media Query for screens smaller than 800px */}
            <style jsx>{`
                @media (max-width: 800px) {
                    .footer-docs, .footer-telegram {
                        display: none; /* Hide Docs and Telegram Support when screen width is below 800px */
                    }
                    ${footerBottomStyle} {
                        flexDirection: "column"; // Ensure items stack vertically on small screens
                        textAlign: "center";
                    }
                }
            `}</style>
        </footer>
    );
}

export default Footer;
