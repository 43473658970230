// File: src/pages/FilterDialog.jsx
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../api';
import SubwayDialog from './SubwayDialog';
import {useAppContext} from '../context/AppContext';  // <-- Import your AppContext hook

const AGE_OPTIONS = [
    {value: '18_22', label: '18-22'},
    {value: '23_28', label: '23-28'},
    {value: '29_plus', label: '29+'},
];
// const BODY_TYPE_OPTIONS = [
//     {value: 'small', label: 'Худенькая'},
//     {value: 'normal', label: 'Нормальная'},
//     {value: 'tasty', label: 'Аппетитная'},
// ];
const HEIGHT_OPTIONS = [
    {value: 'lt160', label: 'До 160'},
    {value: '160_170', label: '160-170'},
    {value: '171_180', label: '171-180'},
    {value: '181_plus', label: '181+'},
];
const CHEST_OPTIONS = [
    {value: 'sm', label: 'Маленькая'},
    {value: 'md', label: 'Средняя'},
    {value: 'lg', label: 'Большая'},
    {value: 'xl', label: 'Очень большая'},
];

// Convert route city slug to numeric ID
function getCityIdFromSlug(slug) {
    return slug === 'spb' ? 2 : 1;
}

function FilterDialog({onClose}) {
    const navigate = useNavigate();
    const {city = 'msk'} = useParams();
    const cityId = getCityIdFromSlug(city);

    // --- Access to global context for saving/restoring filters
    const {getValue, setValue} = useAppContext();

    // Filter states
    const [selectedAges, setSelectedAges] = useState([]);
    const [selectedBodyTypes, setSelectedBodyTypes] = useState([]);
    const [selectedHeights, setSelectedHeights] = useState([]);
    const [selectedChests, setSelectedChests] = useState([]);

    // Subways
    const [allSubways, setAllSubways] = useState([]);
    const [selectedSubways, setSelectedSubways] = useState([]);
    const [subwayDialogOpen, setSubwayDialogOpen] = useState(false);

    // Check if small screen => fill screen
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // Load saved filters from context (if any) on mount
    useEffect(() => {
        const savedFilters = getValue('filters');
        if (savedFilters) {
            setSelectedAges(savedFilters.selectedAges || []);
            setSelectedBodyTypes(savedFilters.selectedBodyTypes || []);
            setSelectedHeights(savedFilters.selectedHeights || []);
            setSelectedChests(savedFilters.selectedChests || []);
            setSelectedSubways(savedFilters.selectedSubways || []);
        }
    }, [getValue]);

    // Resize detection
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 400);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch subways
    useEffect(() => {
        const fetchSubways = async () => {
            try {
                const res = await api.get('/utils/subways');
                setAllSubways(
                    res.data
                        .filter((s) => s.cityId === cityId)
                        .filter((s) => s.name && s.name.trim() !== "")
                        .filter((subway, index, self) =>
                            self.findIndex((item) => item.name === subway.name) === index
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                );
            } catch (error) {
                // console.error('Error fetching subways:', error);
            }
        };
        fetchSubways();
    }, [cityId]);

    // Toggle logic for chips
    const toggleSelection = (value, arr, setArr) => {
        if (arr.includes(value)) {
            setArr(arr.filter((v) => v !== value));
        } else {
            setArr([...arr, value]);
        }
    };

    // Apply => save filters, build query, navigate
    const handleApply = () => {
        // Save in context
        setValue('filters', {
            selectedAges,
            selectedBodyTypes,
            selectedHeights,
            selectedChests,
            selectedSubways,
        });

        // Build query
        const params = new URLSearchParams();
        selectedAges.forEach((v) => params.append('age', v));
        selectedBodyTypes.forEach((v) => params.append('bodyType', v));
        selectedHeights.forEach((v) => params.append('height', v));
        selectedChests.forEach((v) => params.append('chest', v));
        selectedSubways.forEach((id) => params.append('subwayId', id));

        navigate(`/${city}?${params.toString()}`);
        onClose();
    };

    // Reset => clear local state & context
    const handleReset = () => {
        setSelectedAges([]);
        setSelectedBodyTypes([]);
        setSelectedHeights([]);
        setSelectedChests([]);
        setSelectedSubways([]);
    };

    // Prevent background scroll
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    // Close on ESC
    useEffect(() => {
        const handleEsc = (e) => {
            if (e.key === 'Escape') onClose();
        };
        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, [onClose]);

    // --- STYLES ---
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const baseDialogStyle = {
        position: 'relative',
        backgroundColor: '#fff',
        userSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
        overflowX: 'hidden',
        overflowY: 'auto',
        boxSizing: 'border-box',
    };

    const largeScreenStyle = {
        width: '400px',
        maxHeight: '80vh',
        minHeight: '40vh',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        padding: '24px',
        paddingTop: '24px',
    };

    const smallScreenStyle = {
        width: '100vw',
        height: '100vh',
        margin: 0,
        borderRadius: 0,
        padding: '24px',
        paddingTop: '48px',
    };

    const dialogStyle = isSmallScreen
        ? {...baseDialogStyle, ...smallScreenStyle}
        : {...baseDialogStyle, ...largeScreenStyle};

    const closeIconStyle = {
        position: 'absolute',
        top: '16px',
        right: '16px',
        fontSize: '1.3rem',
        color: '#333',
        cursor: 'pointer',
    };

    const titleStyle = {
        margin: 0,
        fontSize: '1.4rem',
        fontWeight: '600',
        textAlign: 'center',
        paddingBottom: '8px',
        borderBottom: '1px solid #eee',
    };

    const sectionStyle = {
        marginTop: '16px',
    };

    const labelStyle = {
        marginBottom: '8px',
        fontWeight: '500',
        fontSize: '1rem',
    };

    const chipContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
    };

    const chipStyle = (selected) => ({
        padding: '8px 14px',
        borderRadius: '20px',
        fontSize: '0.9rem',
        border: '1px solid #ddd',
        backgroundColor: selected ? '#000' : '#fafafa',
        color: selected ? '#fff' : '#000',
        cursor: 'pointer',
        transition: 'background-color 0.2s ease',
    });

    const handleChipMouseEnter = (e, selected) => {
        if (selected) {
            e.currentTarget.style.backgroundColor = '#222'; // darker black
        } else {
            e.currentTarget.style.backgroundColor = '#ddd'; // light gray
        }
    };
    const handleChipMouseLeave = (e, selected) => {
        if (selected) {
            e.currentTarget.style.backgroundColor = '#000';
        } else {
            e.currentTarget.style.backgroundColor = '#fafafa';
        }
    };

    const subwayLinkStyle = {
        fontSize: '0.9rem',
        fontWeight: '500',
        color: '#007bff',
        textDecoration: 'none',
        cursor: 'pointer',
        marginLeft: '8px',
    };

    const buttonRowStyle = {
        display: 'flex',
        gap: '16px',
        marginTop: '24px',
        justifyContent: 'space-between',
    };

    const applyButtonStyle = {
        flex: '1',
        padding: '12px',
        backgroundColor: '#000',
        color: '#fff',
        fontSize: '1rem',
        fontWeight: '600',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        textAlign: 'center',
        transition: 'background-color 0.2s ease',
    };
    const resetButtonStyle = {
        flex: '1',
        padding: '12px',
        backgroundColor: '#fff',         // white
        color: '#000',                   // black text
        border: '1px solid #000',        // black border
        fontSize: '1rem',
        fontWeight: '600',
        borderRadius: '6px',
        cursor: 'pointer',
        textAlign: 'center',
        transition: 'background-color 0.2s ease, color 0.2s ease',
    };

    const handleApplyMouseEnter = (e) => {
        e.currentTarget.style.backgroundColor = '#222'; // darker black
    };
    const handleApplyMouseLeave = (e) => {
        e.currentTarget.style.backgroundColor = '#000'; // original
    };

    // New scheme: reset is white => black on hover
    const handleResetMouseEnter = (e) => {
        e.currentTarget.style.backgroundColor = '#000'; // black
        e.currentTarget.style.color = '#fff';           // white text
    };
    const handleResetMouseLeave = (e) => {
        e.currentTarget.style.backgroundColor = '#fff'; // revert
        e.currentTarget.style.color = '#000';           // revert
    };

    return (
        <>
            <div style={overlayStyle} onClick={onClose}>
                <div style={dialogStyle} onClick={(e) => e.stopPropagation()}>
                    <span style={closeIconStyle} onClick={onClose}>
                        &times;
                    </span>

                    <h2 style={titleStyle}>Фильтры</h2>

                    {/* Age */}
                    <div style={sectionStyle}>
                        <div style={labelStyle}>Возраст</div>
                        <div style={chipContainerStyle}>
                            {AGE_OPTIONS.map(({value, label}) => {
                                const isSelected = selectedAges.includes(value);
                                return (
                                    <div
                                        key={value}
                                        style={chipStyle(isSelected)}
                                        onMouseEnter={(e) => handleChipMouseEnter(e, isSelected)}
                                        onMouseLeave={(e) => handleChipMouseLeave(e, isSelected)}
                                        onClick={() => toggleSelection(value, selectedAges, setSelectedAges)}
                                    >
                                        {label}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* Body Type */}
                    {/*<div style={sectionStyle}>*/}
                    {/*    <div style={labelStyle}>Телосложение</div>*/}
                    {/*    <div style={chipContainerStyle}>*/}
                    {/*        {BODY_TYPE_OPTIONS.map(({value, label}) => {*/}
                    {/*            const isSelected = selectedBodyTypes.includes(value);*/}
                    {/*            return (*/}
                    {/*                <div*/}
                    {/*                    key={value}*/}
                    {/*                    style={chipStyle(isSelected)}*/}
                    {/*                    onMouseEnter={(e) => handleChipMouseEnter(e, isSelected)}*/}
                    {/*                    onMouseLeave={(e) => handleChipMouseLeave(e, isSelected)}*/}
                    {/*                    onClick={() =>*/}
                    {/*                        toggleSelection(value, selectedBodyTypes, setSelectedBodyTypes)*/}
                    {/*                    }*/}
                    {/*                >*/}
                    {/*                    {label}*/}
                    {/*                </div>*/}
                    {/*            );*/}
                    {/*        })}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* Height */}
                    <div style={sectionStyle}>
                        <div style={labelStyle}>Рост</div>
                        <div style={chipContainerStyle}>
                            {HEIGHT_OPTIONS.map(({value, label}) => {
                                const isSelected = selectedHeights.includes(value);
                                return (
                                    <div
                                        key={value}
                                        style={chipStyle(isSelected)}
                                        onMouseEnter={(e) => handleChipMouseEnter(e, isSelected)}
                                        onMouseLeave={(e) => handleChipMouseLeave(e, isSelected)}
                                        onClick={() =>
                                            toggleSelection(value, selectedHeights, setSelectedHeights)
                                        }
                                    >
                                        {label}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* Chest */}
                    <div style={sectionStyle}>
                        <div style={labelStyle}>Грудь</div>
                        <div style={chipContainerStyle}>
                            {CHEST_OPTIONS.map(({value, label}) => {
                                const isSelected = selectedChests.includes(value);
                                return (
                                    <div
                                        key={value}
                                        style={chipStyle(isSelected)}
                                        onMouseEnter={(e) => handleChipMouseEnter(e, isSelected)}
                                        onMouseLeave={(e) => handleChipMouseLeave(e, isSelected)}
                                        onClick={() =>
                                            toggleSelection(value, selectedChests, setSelectedChests)
                                        }
                                    >
                                        {label}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* Subways link */}
                    <div style={sectionStyle}>
                        <div style={labelStyle}>
                            Станции метро
                            <span
                                style={subwayLinkStyle}
                                onClick={() => setSubwayDialogOpen(true)}
                            >
                                {selectedSubways.length
                                    ? ` (выбрано: ${selectedSubways.length})`
                                    : ' (выбрать)'}
                            </span>
                        </div>
                    </div>

                    {/* Button Row: Reset + Apply */}
                    <div style={buttonRowStyle}>
                        <button
                            style={resetButtonStyle}
                            onClick={handleReset}
                            data-umami-event="Exit Filters Clicked"
                            onMouseEnter={handleResetMouseEnter}
                            onMouseLeave={handleResetMouseLeave}
                        >
                            Сбросить
                        </button>
                        <button
                            style={applyButtonStyle}
                            onClick={handleApply}
                            data-umami-event="Submit Filters Clicked"
                            onMouseEnter={handleApplyMouseEnter}
                            onMouseLeave={handleApplyMouseLeave}
                        >
                            Показать анкеты
                        </button>
                    </div>
                </div>
            </div>

            {subwayDialogOpen && (
                <SubwayDialog
                    allSubways={allSubways}
                    initiallySelected={selectedSubways}
                    onClose={() => setSubwayDialogOpen(false)}
                    onApply={(arr) => setSelectedSubways(arr)}
                />
            )}
        </>
    );
}

export default FilterDialog;
