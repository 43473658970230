import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import api from '../../api';
import {useAppContext} from '../../context/AppContext';

function Dashboard() {
    const {getValue} = useAppContext(); // Access authentication token
    const [status, setStatus] = useState(null);
    const [reasonReject, setReasonReject] = useState('');

    useEffect(() => {
        const fetchPrivateProfile = async () => {
            try {
                const authToken = getValue('authToken'); // Retrieve auth token from context

                if (!authToken) {
                    // console.error('Authentication token is missing');
                    return;
                }

                // Make GET request to fetch private profile data
                const response = await api.get('/privateProfile', {
                    headers: {Authorization: `Bearer ${authToken}`},
                });

                const profileData = response.data;

                // Set the status and reasonReject from the profile
                setStatus(profileData.status);
                setReasonReject(profileData.reasonReject || '');
            } catch (error) {
                // console.error('Error fetching private profile:', error);
            }
        };

        fetchPrivateProfile();
    }, [getValue]);

    // Функция для отображения статуса в виде строки
    const getStatusString = (status) => {
        switch (status) {
            case 1:
                return 'Заполните анкету';
            case 2:
                return 'Ожидает модерации';
            case 3:
                return 'Анкета активна';
            case 4:
                return 'Анкета отклонена. Напишите в поддержку';
            default:
                return 'Неизвестный статус. Напишите в поддержку';
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return '#87CEEB';
            case 2:
                return '#FFA07A';
            case 3:
                return '#32CD32';
            case 4:
                return '#DC143C';
            default:
                return '#DC143C';
        }
    };

    const containerStyle = {
        fontFamily: 'Montserrat, sans-serif',
        padding: '16px',
        maxWidth: '600px',
        margin: '0 auto',
        lineHeight: '1.6',
        color: '#333',
    };

    const headingStyle = {
        fontSize: '1.8rem',
        fontWeight: '700',
        marginBottom: '24px',
        textAlign: 'center',
        color: '#000',
    };

    const listStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    };

    const linkStyle = {
        textDecoration: 'none',
        fontSize: '1.1rem',
        fontWeight: '600',
        padding: '12px 16px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        color: '#000',
        border: '1px solid #ddd',
        textAlign: 'center',
        transition: 'all 0.3s ease',
    };

    const linkHoverStyle = {
        backgroundColor: '#000',
        color: '#fff',
    };

    return (
        <div style={containerStyle}>
            <h2 style={headingStyle}>Личный кабинет</h2>

            {status !== null && (
                <>
                    <p
                        style={{
                            textAlign: 'center',
                            fontSize: '1.2rem',
                            fontWeight: '600',
                            color: getStatusColor(status),
                        }}
                    >
                        Статус анкеты: {getStatusString(status)}
                    </p>

                    {/* Show the reasonReject if status=4 and reasonReject is not empty */}
                    {status === 4 && reasonReject.trim() !== '' && (
                        <p
                            style={{
                                textAlign: 'center',
                                fontSize: '1rem',
                                color: '#FF5555',
                                marginTop: '8px',
                                fontWeight: '500',
                            }}
                        >
                            Причина отклонения: {reasonReject}
                            <br/>
                            Внесите правки и отправьте анкету на повторную модерацию.
                        </p>
                    )}
                </>
            )}

            <div style={listStyle}>
                <Link
                    to="/me/profile"
                    style={linkStyle}
                    onMouseOver={(e) => Object.assign(e.target.style, linkHoverStyle)}
                    onMouseOut={(e) => Object.assign(e.target.style, linkStyle)}
                >
                    Анкета
                </Link>
                <Link
                    to="/me/content-requirements"
                    style={linkStyle}
                    onMouseOver={(e) => Object.assign(e.target.style, linkHoverStyle)}
                    onMouseOut={(e) => Object.assign(e.target.style, linkStyle)}
                >
                    Требования к контенту
                </Link>
                <Link
                    to="/me/tariffs-services"
                    style={{
                        ...linkStyle,
                        color: '#aaa',
                        cursor: 'not-allowed',
                        borderColor: '#eee',
                    }}
                >
                    Тарифы и услуги (В разработке)
                </Link>
                <Link
                    to="/me/recharge"
                    style={{
                        ...linkStyle,
                        color: '#aaa',
                        cursor: 'not-allowed',
                        borderColor: '#eee',
                    }}
                >
                    Пополнить счет (В разработке)
                </Link>
                <Link
                    to="/me/support"
                    style={linkStyle}
                    onMouseOver={(e) => Object.assign(e.target.style, linkHoverStyle)}
                    onMouseOut={(e) => Object.assign(e.target.style, linkStyle)}
                >
                    Техподдержка
                </Link>
                <Link
                    to="/me/logout"
                    style={{...linkStyle, backgroundColor: '#aaa', color: '#fff'}}
                    onMouseOver={(e) =>
                        Object.assign(e.target.style, {backgroundColor: '#FF4C4C'})
                    }
                    onMouseOut={(e) =>
                        Object.assign(e.target.style, {backgroundColor: '#aaa'})
                    }
                >
                    Выйти из аккаунта
                </Link>
            </div>
        </div>
    );
}

export default Dashboard;
