import React, {useEffect, useLayoutEffect, useState, useRef} from 'react';

function SubwayDialog({
                          allSubways = [],
                          initiallySelected = [],
                          onClose,
                          onApply,
                      }) {
    const [selected, setSelected] = useState(initiallySelected);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const dialogRef = useRef(null);

    // Prevent background scrolling
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    // Check screen width on mount & resize
    useLayoutEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 400);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Close on ESC
    useEffect(() => {
        const handleEsc = (e) => {
            if (e.key === 'Escape') onClose();
        };
        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, [onClose]);

    // Close if click outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dialogRef.current && !dialogRef.current.contains(e.target)) {
                onClose();
            }
        };
        window.addEventListener('mousedown', handleClickOutside);
        return () => window.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    // Toggle subways
    const toggleSubway = (id) => {
        setSelected((prev) =>
            prev.includes(id) ? prev.filter((val) => val !== id) : [...prev, id]
        );
    };

    // Filter subways by search
    const filtered = allSubways.filter((sw) =>
        sw.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Apply & close
    const handleApply = () => {
        onApply(selected);
        onClose();
    };

    // --- STYLES ---
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'none',
    };

    const baseDialogStyle = {
        display: 'flex',
        flexDirection: 'column', // top -> content -> bottom
        boxSizing: 'border-box',
        position: 'relative',
        backgroundColor: '#fff',
        fontFamily: "'Montserrat', sans-serif",
        userSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
        transition: 'none',
    };

    // On large screens => 400px wide, up to 80vh tall
    const largeScreenStyle = {
        width: '400px',
        maxHeight: '80vh',
        minHeight: '40vh',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
    };

    // On small => full screen
    const smallScreenStyle = {
        width: '100vw',
        height: '100vh',
        margin: 0,
        borderRadius: 0,
    };

    const dialogStyle = isSmallScreen
        ? {...baseDialogStyle, ...smallScreenStyle}
        : {...baseDialogStyle, ...largeScreenStyle};

    const closeIconStyle = {
        position: 'absolute',
        top: '16px',
        right: '16px',
        fontSize: '1.3rem',
        color: '#333',
        cursor: 'pointer',
        transition: 'color 0.2s ease',
    };

    const titleStyle = {
        margin: 0,
        fontSize: '1.4rem',
        fontWeight: '600',
        textAlign: 'center',
        padding: '24px 0 8px 0',
        borderBottom: '1px solid #eee',
    };

    const contentStyle = {
        flex: 1,
        overflowY: 'auto',
        padding: '16px 24px',
    };

    const searchContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
    };

    const searchInputStyle = {
        width: isSmallScreen ? '100%' : '300px',
        padding: '10px 12px',
        borderRadius: '6px',
        border: '1px solid #ccc',
        fontSize: '1rem',
        outline: 'none',
    };

    // We'll do a row for each subway
    const subwayRowStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 0',
        transition: 'background-color 0.2s ease',
    };

    // Footer
    const footerStyle = {
        borderTop: '1px solid #eee',
        padding: '16px 24px',
    };

    const applyButtonStyle = {
        width: '100%',
        padding: '12px',
        backgroundColor: '#000',
        color: '#fff',
        fontSize: '1rem',
        fontWeight: '600',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        textAlign: 'center',
        transition: 'background-color 0.2s ease',
    };

    // Hover handlers:
    // 1) Close icon
    const handleCloseIconEnter = (e) => {
        e.currentTarget.style.color = '#000'; // darken
    };
    const handleCloseIconLeave = (e) => {
        e.currentTarget.style.color = '#333'; // revert
    };

    // 2) Subway row
    const handleRowEnter = (e) => {
        e.currentTarget.style.backgroundColor = '#f0f0f0'; // or any highlight
    };
    const handleRowLeave = (e) => {
        e.currentTarget.style.backgroundColor = ''; // revert
    };

    // 3) Apply button
    const handleApplyEnter = (e) => {
        e.currentTarget.style.backgroundColor = '#222';
    };
    const handleApplyLeave = (e) => {
        e.currentTarget.style.backgroundColor = '#000';
    };

    // 4) Make the checkbox check color black (via accent-color)
    // We'll do it inline on the <input> style

    return (
        <div style={overlayStyle}>
            <div style={dialogStyle} ref={dialogRef}>
                {/* Close Icon */}
                <span
                    style={closeIconStyle}
                    onClick={onClose}
                    onMouseEnter={handleCloseIconEnter}
                    onMouseLeave={handleCloseIconLeave}
                >
          &times;
        </span>

                <h2 style={titleStyle}>Выбор станций</h2>

                {/* Scrollable content for subways */}
                <div style={contentStyle}>
                    <div style={searchContainerStyle}>
                        <input
                            style={searchInputStyle}
                            type="text"
                            placeholder="Поиск станции..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    {filtered.length === 0 ? (
                        <p style={{textAlign: 'center'}}>Нет станций</p>
                    ) : (
                        filtered.map((sw) => {
                            const checked = selected.includes(sw.id);
                            return (
                                <div
                                    key={sw.id}
                                    style={subwayRowStyle}
                                    onMouseEnter={handleRowEnter}
                                    onMouseLeave={handleRowLeave}
                                >
                                    <input
                                        type="checkbox"
                                        checked={checked}
                                        onChange={() => toggleSubway(sw.id)}
                                        style={{
                                            marginRight: '8px',
                                            accentColor: '#000', // black check color
                                        }}
                                    />
                                    <span style={{cursor: 'pointer'}} onClick={() => toggleSubway(sw.id)}>
                    {sw.name}
                  </span>
                                </div>
                            );
                        })
                    )}
                </div>

                {/* Footer with button */}
                <div style={footerStyle}>
                    <button
                        style={applyButtonStyle}
                        onClick={handleApply}
                        onMouseEnter={handleApplyEnter}
                        onMouseLeave={handleApplyLeave}
                    >
                        Применить
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SubwayDialog;
