// src/pages/Support.jsx
import React from 'react';
import {useNavigate} from "react-router-dom";

function Support() {
    const navigate = useNavigate();

    const style = {
        fontFamily: 'Montserrat, sans-serif',
        padding: '16px',
        lineHeight: '1.6',
        color: '#333',
        maxWidth: '800px',
        margin: '0 auto',
    };

    const headingStyle = {
        fontSize: '1.5rem',
        fontWeight: '700',
        margin: '16px 0',
        color: '#000',
        textAlign: 'center',
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '16px',
    };

    const buttonStyle = {
        padding: '6px 12px',
        fontSize: '1.9rem',
        fontWeight: '900',
        color: '#fff',
        backgroundColor: '#000',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const buttonHoverStyle = {
        backgroundColor: '#000',
    };

    return (
        <div style={style}>
            <div style={buttonContainerStyle}>
                <button
                    style={buttonStyle}
                    onClick={() => navigate('/me/dashboard')}
                    onMouseOver={(e) => Object.assign(e.target.style, buttonHoverStyle)}
                    onMouseOut={(e) => Object.assign(e.target.style, buttonStyle)}
                >
                    ←
                </button>
            </div>
            <h2 style={headingStyle}>Поддержка</h2>

            <p>
                Мы обрабатываем заявки ежедневно с 10:00 до 20:00. Модерация фотографий и профилей начинается в 12:00. Проверить статус модерации можно в личном кабинете.
            </p>

            <a
                href="https://t.me/marketplace_18plus"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    display: 'inline-block',
                    padding: '8px 16px',
                    marginTop: '16px',
                    backgroundColor: '#000',
                    color: '#fff',
                    borderRadius: '6px',
                    textDecoration: 'none',
                }}
            >
                Написать в Telegram
            </a>
        </div>
    );
}

export default Support;
