import React from 'react';

const Terms = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1>СОГЛАШЕНИЕ</h1>
            <h2>ОБ ИСПОЛЬЗОВАНИИ САЙТА, КОНТЕНТА И СЕРВИСОВ САЙТА В СЕТИ «ИНТЕРНЕТ»</h2>
            <h3>(ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ, ОФЕРТА)</h3>
            <p>г. Москва «01» января 2025 г.</p>
            <p>
                Настоящее Соглашение, в том числе, является публичной офертой и определяет условия использования Сайта, Контента и Сервисов Сайта, размещенных на сайте в сети Интернет по адресу: <a href="https://marketplace.dating">https://marketplace.dating</a> (далее - Сайт) Посетителями указанного Сайта.
                Использование Сайта, Контента и Сервисов Сайта означает безоговорочное согласие Пользователя с настоящим Соглашением, всеми приложениями к нему и указанными в них условиями, а в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сайта, Контента и сервисов Сайта.
            </p>

            <h3>1. ПОНЯТИЯ И ТЕРМИНЫ</h3>
            <p>
                В рамках настоящего Соглашения и всех приложений к нему используются следующие понятия и термины:
                <ul>
                    <li>Администратор (Владелец Сайта, Администрация Сайта) - лицо, являющееся администратором сайта в сети Интернет по адресу: <a href="https://marketplace.dating">https://marketplace.dating</a> Marketplace Incorporation, Telegram аккаунт: <a href="https://t.me/marketplace_18plus" target="_blank">https://t.me/marketplace_18plus</a>, а также лица, уполномоченные Владельцем Сайта на осуществление управления Сайтом и иные действия, связанные с его использованием. Администрация Сайта действует от имени Владельца Сайта, если иное не будет указано отдельно.</li>
                    <li>Анкета – структурированный набор данных о зарегистрированном Пользователе, в том числе содержащий Контент Пользователя для целей размещения, ознакомления и приглашения делать оферты (предложение оказания услуг массажа иными Пользователями).</li>
                    <li>Авторизация - процесс определения достоверности полномочий Пользователя на доступ к учетной записи/аккаунту, созданной при Регистрации.</li>
                    <li>Контент – любые информационные материалы, размещенные на Сайте, а также входящие в их состав или размещаемые отдельно фотографии, текстовые, графические, аудиовизуальные данные, результаты интеллектуальной деятельности и/или средства индивидуализации, в том числе и персональные данные, которые не являются предметом частной жизни, предоставлены пользователем на добровольной основе, размещены и распространены с согласия Пользователя.</li>
                    <li>Массаж – немедицинский массаж (бытовой косметический), который проводится на здоровых людях, с соблюдением определенных этических и эстетических норм, с использованием комплексного воздействия на органы чувств клиента с целью укрепления здоровья, моделирования фигуры и/или коррекции психоэмоционального состояния клиента, конечной целью массажа является гармонизация тела, сознания и души человека.</li>
                    <li>Оферта - публичное предложение Пользователя любому иному Пользователю заключить на его условиях договор оказания услуг массажа (далее - договор). Оферта является публичной (п. 2 ст. 437 ГК РФ). Оферта вступает в силу с момента ее размещения на Сайте по адресу: <a href="https://marketplace.dating/info">https://marketplace.dating/info</a> и действует до ее отзыва.</li>
                    <li>Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных - Пользователю).</li>
                    <li>Пользовательское соглашение (Соглашение) – настоящее соглашение со всеми приложениями, заключаемое между Пользователем и Администратором.</li>
                    <li>Пользователь Сайта (Пользователь) – дееспособное совершеннолетнее физическое лицо, осуществляющее доступ к Сайту и Сервисам Сайта посредством сети Интернет, а также дееспособное совершеннолетнее физическое лицо, прошедшее регистрацию, создавшее учетную запись/аккаунт, добровольно предоставившее свои персональные данные, информацию и фотографии (Контент), Анкету с целью размещения на Сайте для оказания услуг массажа (Модель). Для целей настоящего Соглашения понятия «Пользователь» и «Модель» имеют равную силу.</li>
                    <li>Регистрация – процесс создания учетной записи/аккаунта на Сервисе.</li>
                    <li>Сайт в сети Интернет (Сайт) - совокупность программ для ЭВМ и иной информации, содержащейся в информационной системе, доступ к которой осуществляется через Интернет по доменному имени <a href="https://marketplace.dating">https://marketplace.dating</a> и сетевым адресам, которые позволяют идентифицировать сайт.</li>
                    <li>Сервисы Сайта - интерактивные (диалоговые) программные компоненты на страницах Сайта, используемые для интеграции с информационными системами и предоставляющие пользователям Сайта определенные возможности по доступу к информации на Сайт (далее - Сервисы, Сервисы Сайта).</li>
                    <li>Учетная запись/аккаунт - раздел Сайта, доступный Пользователю после регистрации, посредством которого Пользователь осуществляет управление своей учетной записью (аккаунтом), размещение информации и иные действия, связанные с использованием Сайта и Сервисов Сайта.</li>
                    <li>Частная жизнь - та область жизнедеятельности человека, которая относится к отдельному лицу, касается только его и не подлежит контролю со стороны общества и государства, если носит непротивоправный характер. Соответственно, лишь само лицо вправе определить, какие именно сведения, имеющие отношение к его частной жизни, должны оставаться в тайне, а потому и сбор, хранение, использование и распространение такой информации, не доверенной никому, не допускается без согласия данного лица, как того требует Конституция Российской Федерации.</li>
                    <li>Cookies-файл (Cookies) - текстовый файл, отправляемый веб-сервером и хранимый на компьютере Пользователя, используемый для аутентификации Пользователя, отслеживания состояния его сеанса и хранения статистики об использовании Пользователем Сайта.</li>
                </ul>
            </p>

            <h3>2. ПРЕДМЕТ СОГЛАШЕНИЯ</h3>
            <p>
                <strong>2.1.</strong> Администратор предоставляет Пользователям возможность использования Сайта и Сервисов Сайта исключительно в следующих целях:
                <ul>
                    <li>Прохождения регистрации, создания учетной записи/аккаунта, добровольного предоставления своих персональных данных, Контента, размещения и продвижения Анкеты для оказания услуг массажа.</li>
                    <li>Ознакомления Пользователей с Анкетами и Контентом иных Пользователей и обеспечения возможности акцепта опубликованных оферт.</li>
                </ul>
                <strong>2.2.</strong> Администратор разъясняет, а Пользователь понимает, принимает и соглашается с тем, что Сайт и Сервисы Сайта:
                <ul>
                    <li>Не предназначены для возможности удовлетворения сексуальных потребностей Посетителей.</li>
                    <li>Не предназначены для возможности оказания сексуальных услуг, целью которых является удовлетворение полового влечения (половое сношение, лесбиянство или иные действия сексуального характера, условием совершения которых является денежное или любое другое вознаграждение).</li>
                    <li>Не предназначены для возможности оказания сексуальных услуг в форме петтинга (поцелуи, объятия, поглаживания, трение гениталий и т.д.) или мастурбации, направленных на достижение Пользователями семяизвержения, условием совершения которых является денежное или любое другое вознаграждение.</li>
                    <li>Не предназначены для возможности размещения информации порнографического характера (информация, представляемая в виде натуралистических изображения или описания половых органов человека и (или) полового сношения либо сопоставимого с половым сношением действия сексуального характера, в том числе такого действия, совершаемого в отношении животного).</li>
                </ul>
            </p>

            <h3>3. ОБЩИЕ УСЛОВИЯ</h3>
            <p>
                Настоящее Соглашение определяет условия использования Контента и Сервисов Сайта, размещенных на сайте в сети Интернет по адресу: <a href="https://marketplace.dating">https://marketplace.dating</a> Посетителями указанного Сайта, а также регулирует отношения между Администратором и Пользователями Сайта, возникающие в связи с использованием Сайта.
                Положения настоящего Соглашения устанавливаются, изменяются и отменяются Администратором в одностороннем порядке без предварительного уведомления. С момента размещения на Сайте новой редакции Соглашения предыдущая редакция считается утратившей свою силу.
                К правам и обязанностям сторон, возникшим на основании редакции Соглашения, утратившей свою силу, применяются положения действующей (последней) редакции Соглашения, если иное не будет установлено Соглашением или не вытекает из характера возникших между сторонами отношений.
                Действующая редакция Соглашения размещена в сети Интернет по адресу <a href="https://marketplace.dating/info">https://marketplace.dating/info</a>.
                Получая доступ к Сайту, Пользователь считается присоединившимся к настоящему Соглашению.
                Акцептом Оферты признается ответ Пользователя, которому адресована оферта (публичное предложение Пользователя любому иному Пользователю заключить на его условиях договор оказания услуг массажа), о ее принятии. Акцепт должен быть полным и безоговорочным.
                Договор оказания услуг массажа заключается непосредственно между пользователями без какого-либо участия Администратора.
            </p>

            <h3>4. ОБЯЗАТЕЛЬСТВА ПОЛЬЗОВАТЕЛЯ</h3>
            <p>
                <strong>4.1.</strong> Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие российское законодательство или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских и/или смежных прав, общепринятые нормы морали и нравственности, а также любых действий, которые приводят или могут привести к нарушению нормальной работы Сайта и сервисов Сайта.
                Пользователь подтверждает, что является дееспособным совершеннолетним физическим лицом.
                Пользователь обязуется предоставлять достоверную, необходимую и актуальную информацию о себе и об оказываемой услуге.
                На представленных для размещения фотографиях должен быть запечатлен непосредственно зарегистрированный совершеннолетний пользователь.
                Пользователь подтверждает, что представленные фотографии не являются объектом авторского право третьих лиц, а принадлежат непосредственно зарегистрированному совершеннолетнему Пользователю и на распространение которых Пользователь дает свое согласие.
                Пользователь подтверждает, что представленная информация и фотографии (Контент) не являются предметом его частной жизни, а также предметом частной жизни иных лиц.
            </p>
            <p>
                <strong>4.2. Пользователю запрещено:</strong>
                <ul>
                    <li>Указание на возможность оказывать сексуальные услуги, целью которых является удовлетворение полового влечения (половое сношение, лесбиянство или иные действия сексуального характера, условием совершения которых является денежное или любое другое вознаграждение).</li>
                    <li>Указание на возможность оказывать сексуальные услуги в форме петтинга (поцелуи, объятия, поглаживания, трение гениталий и т.д.) или мастурбации, направленные на достижение клиентами семяизвержения.</li>
                    <li>Фотографии порнографического характера, то есть натуралистические изображения или описания половых органов человека и (или) полового сношения либо сопоставимого с половым сношением действия сексуального характера.</li>
                    <li>Информация и фотографии, выраженные в неприличной форме, которые оскорбляют человеческое достоинство и общественную нравственность, свидетельствуют о явном неуважении к обществу, государству, официальным государственным символам Российской Федерации, Конституции Российской Федерации или органам, осуществляющим государственную власть в Российской Федерации.</li>
                    <li>Употребление нецензурной брани, которая оскорбляет человеческое достоинство и общественную нравственность.</li>
                    <li>Оказание любых услуг лицом и лицам, не достигшим совершеннолетия, оказание любых иных услуг, противоречащих требованиям и условиям настоящего Соглашения.</li>
                    <li>Распространение информации, которая направлена на пропаганду войны, разжигание национальной, расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или административная ответственность.</li>
                    <li>Распространение материалов, производимых и (или) распространяемых иностранным агентом в связи с осуществлением им вида деятельности, установленного действующим законодательством.</li>
                    <li>Распространение материалов с порнографическими изображениями несовершеннолетних и (или) объявлений о привлечении несовершеннолетних в качестве исполнителей для участия в зрелищных мероприятиях порнографического характера; информации, направленной на склонение или иное вовлечение несовершеннолетних в совершение противоправных действий, представляющих угрозу для их жизни и (или) здоровья либо для жизни и (или) здоровья иных лиц; информации, пропагандирующей нетрадиционные сексуальные отношения и (или) предпочтения, педофилию, смену пола, отказ от деторождения.</li>
                    <li>Распространение недостоверной информации, которая порочит честь и достоинство гражданина (физического лица) или подрывает его репутацию и связана с обвинением гражданина (физического лица) в совершении преступления.</li>
                    <li>Распространение объектов авторских и (или) смежных прав без разрешения правообладателя.</li>
                </ul>
            </p>

            <h3>5. ОБЯЗАТЕЛЬСТВА АДМИНИСТРАТОРА</h3>
            <p>
                <strong>5.1.</strong> Администратор предоставляет Пользователю имеющиеся возможности Сайта и Сервиса.
                Администратор является обладателем исключительных прав на использование Сайта, в том числе входящих в его состав программ для ЭВМ и баз данных, информационных материалов, графических изображений, являющихся элементами пользовательского интерфейса.
            </p>
            <p>
                <strong>5.2.</strong> Администратор является информационным посредником и оказывает услуги по передаче, хранению и обеспечению доступа посредством сети Интернет к предоставляемой Пользователями информации, графическим изображениям и иным материалам (Контенту). Любая информация, предоставляемая Пользователями и передаваемая посредством Сайта, может корректироваться (дополняться, изменяться, удаляться) Администратором в случае, если она нарушает правила использования сайта.
            </p>
            <p>
                <strong>5.3.</strong> Администратор не несет ответственности за посещение и использование Пользователями внешних ресурсов, ссылки на которые могут содержаться на Сайте.
            </p>
            <p>
                <strong>5.4.</strong> Администратор вправе, но не обязана осуществлять модерацию сообщений, фотографий и иных материалов, размещаемых Пользователями на Сайте. Решения об одобрении или отклонении пользовательского контента принимаются Администратором на основе их личных суждений, знаний, опыта, логики и целесообразности. Результаты модерации могут быть оспорены, но это не гарантирует, что решение будет изменено.
            </p>
            <p>
                <strong>5.5.</strong> Администрация не занимается рассмотрением и разрешением споров и конфликтных ситуаций, возникающих между Пользователями, однако оставляет за собой право заблокировать доступ Пользователя к Сайту в случае получения посредством в Telegram аккаунт: <a href="https://t.me/marketplace_18plus" target="_blank">https://t.me/marketplace_18plus</a> от других Пользователей мотивированных жалоб на некорректное поведение данного Пользователя на Сайте.
            </p>
            <p>
                <strong>5.6.</strong> Администрация вправе потребовать от пользователя подтвердить право на использование фотографий в Анкете или профиле, а также достоверность предоставляемой информации.
            </p>
            <p>
                <strong>5.7.</strong> Администратор не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками, связанными с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой регистрации, товарами или услугами, доступными на Сайте или полученными через внешние сайты или ресурсы, либо иные контакты Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.
            </p>
            <p>
                <strong>5.8.</strong> Администратор не гарантирует, что доступ к Сайту будет предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть получены с использованием Сайта, будут точными и надежными и могут использоваться для каких-либо целей или в каком-либо качестве.
            </p>

            <h3>6. ПРОЧИЕ УСЛОВИЯ</h3>
            <p>
                На Сайте применяется технология идентификации, основанная на использовании файлов cookies. При доступе Пользователя к Сайту на компьютер, используемый им для доступа, могут быть записаны файлы cookies, которые в дальнейшем будут использованы для автоматической авторизации Пользователя на Сайте, а также для сбора статистических данных, в частности о посещаемости Сайта. Если Пользователь полагает, что по тем или иным причинам использование технологии cookies для него неприемлемо, он вправе запретить сохранение файлов cookies на компьютере, используемом им для доступа к Сайту, соответствующим образом настроив браузер.
            </p>
            <p>
                Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации.
            </p>
            <p>
                Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.
            </p>
            <p>
                Администратор вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают незамедлительно с момента размещения новой версии Соглашения на сайте. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта.
            </p>
            <p>
                Использование Сайта и сервисов Сайта Пользователь подтверждает, что принимает условия настоящего Соглашения, а также Политики конфиденциальности Сайта, Отказа от ответственности (Disclaimer), являющихся неотъемлемой частью настоящего Соглашения и размещенных на странице по адресу: <a href="https://marketplace.dating/info">https://marketplace.dating</a>.
            </p>

            <p>
                Приложение: Политика конфиденциальности Сайта в сети «Интернет», Отказ от ответственности (Disclaimer), Требования к Анкете и Контенту.
            </p>
        </div>
    );
};

export default Terms;
