// src/pages/TariffsServices.jsx
import React from 'react';

function TariffsServices() {
    return (
        <div style={{ fontFamily: 'sans-serif', padding: 16 }}>
            <p>Функциональность в разработке</p>
        </div>
    );
}

export default TariffsServices;
