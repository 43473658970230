import React, {useEffect, useState, useRef} from 'react';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import api from '../api';

// Суффиксы возраста
function getAgeSuffix(age) {
    const lastDigit = age % 10;
    const lastTwo = age % 100;
    if (lastTwo >= 11 && lastTwo <= 14) return 'лет';
    if (lastDigit === 1) return 'год';
    if (lastDigit >= 2 && lastDigit <= 4) return 'года';
    return 'лет';
}

// Вычисление возраста
function calculateAge(birthDateString) {
    if (!birthDateString) return null;
    const birthDate = new Date(birthDateString);
    const now = new Date();
    let age = now.getFullYear() - birthDate.getFullYear();
    const m = now.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

function Models() {
    const {city} = useParams(); // "msk" or "spb"
    const [searchParams] = useSearchParams();

    // Список моделей + ошибки и т.д.
    const [models, setModels] = useState([]);
    const [error, setError] = useState('');

    // Флаги загрузки и наличия следующих страниц
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    // Первый рендер (пока грузим page=1)
    const [initialLoading, setInitialLoading] = useState(true);

    // "msk" -> 1, "spb" -> 2
    const cityIdWanted = city === 'spb' ? 2 : 1;

    // Текущая страница
    const [page, setPage] = useState(1);

    // ---- [NEW] Реальное мгновенное хранилище для "идёт ли загрузка"
    //            Нужен, чтобы handleScroll при быстром скролле не вызывал setPage многократно
    const loadingMoreRef = useRef(false);

    // 1) Функция для загрузки данных
    async function fetchPage(pageNumber, clear = false) {
        try {
            // Здесь НЕ проверяем loadingMore, чтобы не ломать другую логику
            // Просто начинаем запрос
            if (pageNumber === 1) setError('');
            setLoadingMore(true);               // оставляем как было

            const queryParams = buildQueryParams(pageNumber);
            const response = await api.get('/private', {
                params: queryParams,
                paramsSerializer: (params) => {
                    const urlSearch = new URLSearchParams();
                    Object.entries(params).forEach(([key, val]) => {
                        if (Array.isArray(val)) {
                            val.forEach((v) => urlSearch.append(key, v));
                        } else {
                            urlSearch.append(key, val);
                        }
                    });
                    return urlSearch.toString();
                },
            });

            const newData = response.data;
            if (newData.length === 0) {
                // Пустой ответ => нет следующей страницы
                setHasMore(false);
            }

            if (clear) {
                setModels(newData);
            } else {
                setModels((prev) => [...prev, ...newData]);
            }
        } catch (err) {
            setError('Ошибка при загрузке данных');
        } finally {
            // По завершении запроса снимаем "загрузка"
            setLoadingMore(false);
            loadingMoreRef.current = false; // [NEW] освободили ref
        }
    }

    // 2) Строим параметры (не меняем, это не относится к пагинации)
    function buildQueryParams(currPage) {
        const paramsForBackend = {cityId: cityIdWanted, Page: currPage};

        // Пример: Age
        const ageFilters = searchParams.getAll('age');
        if (ageFilters.length > 0) {
            let minAge = 60;
            let maxAge = 18;
            ageFilters.forEach((a) => {
                if (a === '18_22') {
                    minAge = 18;
                    maxAge = Math.max(maxAge, 22);
                } else if (a === '23_28') {
                    minAge = Math.min(minAge, 23);
                    maxAge = Math.max(maxAge, 28);
                } else if (a === '29_plus') {
                    minAge = Math.min(minAge, 29);
                    maxAge = 60;
                }
            });
            paramsForBackend.AgeFrom = minAge;
            paramsForBackend.AgeTo = maxAge;
        }

        // bodyType -> approximate weight
        const bodyTypes = searchParams.getAll('bodyType');
        if (bodyTypes.length > 0) {
            let minWeight = 120;
            let maxWeight = 40;
            bodyTypes.forEach((bt) => {
                if (bt === 'small') {
                    minWeight = 40;
                    maxWeight = Math.max(maxWeight, 50);
                } else if (bt === 'normal') {
                    minWeight = Math.min(minWeight, 50);
                    maxWeight = Math.max(maxWeight, 70);
                } else if (bt === 'tasty') {
                    minWeight = Math.min(minWeight, 70);
                    maxWeight = 120;
                }
            });
            paramsForBackend.WeightFrom = minWeight;
            paramsForBackend.WeightTo = maxWeight;
        }

        // height
        const heightFilters = searchParams.getAll('height');
        if (heightFilters.length > 0) {
            let minHeight = 200;
            let maxHeight = 140;
            heightFilters.forEach((hf) => {
                if (hf === 'lt160') {
                    minHeight = 140;
                    maxHeight = Math.max(maxHeight, 159);
                } else if (hf === '160_170') {
                    minHeight = Math.min(minHeight, 160);
                    maxHeight = Math.max(maxHeight, 170);
                } else if (hf === '171_180') {
                    minHeight = Math.min(minHeight, 171);
                    maxHeight = Math.max(maxHeight, 180);
                } else if (hf === '181_plus') {
                    minHeight = Math.min(minHeight, 181);
                    maxHeight = 200;
                }
            });
            paramsForBackend.HeightFrom = minHeight;
            paramsForBackend.HeightTo = maxHeight;
        }

        // chest
        const chestFilters = searchParams.getAll('chest');
        if (chestFilters.length > 0) {
            let minBust = 6;
            let maxBust = 1;
            chestFilters.forEach((cf) => {
                if (cf === 'sm') {
                    minBust = 1;
                    maxBust = Math.max(maxBust, 1);
                } else if (cf === 'md') {
                    minBust = Math.min(minBust, 2);
                    maxBust = Math.max(maxBust, 3);
                } else if (cf === 'lg') {
                    minBust = Math.min(minBust, 4);
                    maxBust = Math.max(maxBust, 5);
                } else if (cf === 'xl') {
                    minBust = Math.min(minBust, 5);
                    maxBust = 6;
                }
            });
            let maxBustStr;
            if (maxBust === 6) {
                maxBustStr = '5+';
            } else {
                maxBustStr = maxBust;
            }
            paramsForBackend.BustFrom = minBust;
            paramsForBackend.BustTo = maxBustStr;
        }

        // subway
        const subwayIds = searchParams.getAll('subwayId');
        if (subwayIds.length > 0) {
            paramsForBackend.subwayId = subwayIds;
        }

        return paramsForBackend;
    }

    // 3) При каждом изменении searchParams, city => грузим page=1 заново
    useEffect(() => {
        let mounted = true;

        async function loadInitial() {
            setInitialLoading(true);
            setModels([]);
            setError('');
            setHasMore(true);
            setPage(1);

            try {
                await fetchPage(1, true);
            } finally {
                if (mounted) setInitialLoading(false);
            }
        }

        loadInitial();
        return () => {
            mounted = false;
        };
    }, [searchParams, cityIdWanted]);

    // 4) При смене page (кроме первого) загружаем следующую порцию
    useEffect(() => {
        if (page === 1) return;
        if (!hasMore) return;

        fetchPage(page, false);
    }, [page, hasMore]);

    // 5) Событие прокрутки => увеличиваем page
    useEffect(() => {
        if (!hasMore) return;

        function handleScroll() {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            const docHeight = document.documentElement.offsetHeight;

            if (windowHeight + scrollTop >= docHeight - 200) {
                // Проверяем ref, чтобы не дергать много раз за одно событие скролла
                if (!loadingMoreRef.current && hasMore) {
                    // Ставим локальный флаг
                    loadingMoreRef.current = true;
                    // Официально ставим loadingMore в стейте для UI (крутилка)
                    setLoadingMore(true);
                    // Увеличиваем страницу
                    setPage((prev) => prev + 1);
                }
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore]);

    // Рендер крутилки при первой загрузке
    if (initialLoading) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <style>{`
                    @keyframes spin {
                        0%   { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}</style>
                <div
                    style={{
                        width: '30px',
                        height: '30px',
                        border: '3px solid #ccc',
                        borderTop: '3px solid #000',
                        borderRadius: '50%',
                        animation: 'spin 1s linear infinite',
                    }}
                />
            </div>
        );
    }

    // --- Ниже весь ваш layout (верстка) без изменений ---
    const containerStyle = {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '24px',
    };

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '24px',
    };

    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',
        color: 'inherit',
        overflow: 'hidden',
        transition: 'transform 0.2s',
    };

    const photoContainerStyle = {
        position: 'relative',
        width: '100%',
        paddingTop: '150%',
        overflow: 'hidden',
        backgroundColor: '#fff',
    };

    const imageStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.4)',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'opacity 0.3s',
        opacity: 0,
    };

    const infoContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0px',
    };

    const nameStyle = {
        margin: 0,
        fontSize: '1.2rem',
        fontWeight: '700',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: '1 1 auto',
        minWidth: 0,
    };

    const statsStyle = {
        margin: 0,
        fontSize: '1rem',
        fontWeight: '500',
        textAlign: 'right',
        whiteSpace: 'nowrap',
        flex: '0 0 auto',
    };

    const dividerStyle = {
        width: '100%',
        height: '1px',
        backgroundColor: '#ddd',
        marginTop: '12px',
    };

    const handleMouseEnter = (e) => {
        const overlay = e.currentTarget.querySelector('.hoverOverlay');
        if (overlay) overlay.style.opacity = '1';
    };

    const handleMouseLeave = (e) => {
        const overlay = e.currentTarget.querySelector('.hoverOverlay');
        if (overlay) overlay.style.opacity = '0';
    };

    return (
        <div style={containerStyle}>
            {error && (
                <div style={{textAlign: 'center', color: 'red', marginBottom: '16px'}}>
                    {error}
                </div>
            )}

            {!error && models.length === 0 ? (
                <div style={{textAlign: 'center', fontSize: '1.1rem', color: '#333'}}>
                    Никто не нашелся. Попробуйте другие фильтры.
                </div>
            ) : (
                <div style={gridStyle}>
                    {models.map((model) => {
                        const ageNum = calculateAge(model.birthDate);
                        const ageSuffix = ageNum !== null ? getAgeSuffix(ageNum) : '';
                        // const ageString = ageNum ? `${ageNum} ${ageSuffix}` : '';

                        let mainPhotoUrl = '';
                        if (model.photos && model.photos.length > 0) {
                            const primaryPhoto = model.photos.find((p) => p.isPrimary);
                            mainPhotoUrl = primaryPhoto
                                ? primaryPhoto.path
                                : model.photos[0].path;
                        }

                        const heightVal = model.height || '';
                        const bustVal = model.bust || '';
                        const priceVal = model.price || '';
                        const subwayVal = model.subway ? model.subway.name : '';
                        // Пример: heightVal, bustVal, etc.

                        return (
                            <Link
                                key={model.id}
                                to={`/${city}/private/${model.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={cardStyle}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.transform = 'translateY(-5px)';
                                    e.currentTarget.style.boxShadow = '0 4px 12px rgba(0,0,0,0.15)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.transform = 'translateY(0)';
                                    e.currentTarget.style.boxShadow = 'none';
                                }}
                            >
                                <div
                                    style={photoContainerStyle}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <img
                                        src={mainPhotoUrl}
                                        alt={model.name}
                                        title={model.name}
                                        style={imageStyle}
                                    />
                                    <div className="hoverOverlay" style={overlayStyle}>
                                        <p style={{margin: '0 0 8px', fontSize: '1rem', fontWeight: '600'}}>
                                            {heightVal} см │ {bustVal} бюст
                                            {/*от {priceVal}₽*/}
                                        </p>
                                        <p style={{margin: 0, fontSize: '0.9rem'}}>
                                            {subwayVal}
                                        </p>
                                    </div>
                                </div>

                                <div style={infoContainerStyle}>
                                    <p style={nameStyle}>{model.name}, {ageNum} </p>
                                    <p style={statsStyle}>
                                        от {priceVal}₽
                                    </p>
                                    {/*<p style={statsStyle}>*/}
                                    {/*    {heightVal} см │ {ageString} │ {bustVal} бюст*/}
                                    {/*</p>*/}
                                </div>

                                <div style={dividerStyle}></div>
                            </Link>
                        );
                    })}
                </div>
            )}

            {hasMore && (
                <div style={{textAlign: 'center', marginTop: '24px'}}>
                    {loadingMore && (
                        <>
                            <style>{`
                                @keyframes spin {
                                    0%   { transform: rotate(0deg); }
                                    100% { transform: rotate(360deg); }
                                }
                            `}</style>
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: '30px',
                                    height: '30px',
                                    border: '3px solid #ccc',
                                    borderTop: '3px solid #000',
                                    borderRadius: '50%',
                                    animation: 'spin 1s linear infinite',
                                }}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default Models;
