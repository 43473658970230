import React, {createContext, useContext, useState, useEffect} from 'react';

const AppContext = createContext(null);

// We keep a module-level reference to "setValue" so non-React code can call it
let globalSetValue = null;

/**
 * Called by AppProvider to store its setValue function
 * so non-React modules can call it.
 */
export function setGlobalSetValue(fn) {
    globalSetValue = fn;
}

/**
 * A utility that uses the stored "setValue" to clear tokens,
 * then do any redirect you want.
 */
export function clearAuthViaContext() {
    if (globalSetValue) {
        globalSetValue('authToken', null);
        globalSetValue('privateProfile', null);
    }
    // After clearing tokens, do your redirect
    window.location.href = '/login';
}

// Expose a custom hook to read context from inside React components
export const useAppContext = () => {
    return useContext(AppContext);
};

export const AppProvider = ({children}) => {
    const [state, setState] = useState(() => {
        const storedState = localStorage.getItem('appState');
        return storedState ? JSON.parse(storedState) : {};
    });

    const setValue = (key, value) => {
        setState((prevState) => {
            const updatedState = {...prevState, [key]: value};
            localStorage.setItem('appState', JSON.stringify(updatedState));
            return updatedState;
        });
    };

    const getValue = (key) => {
        return state[key] || null;
    };

    // On mount, store "setValue" in the global module variable
    // so interceptor can call it if needed
    useEffect(() => {
        setGlobalSetValue(setValue);
    }, [setValue]);

    return (
        <AppContext.Provider value={{state, setValue, getValue}}>
            {children}
        </AppContext.Provider>
    );
};
