// File: src/App.js
import React, {useEffect, useLayoutEffect, useState} from 'react';
import { updateURLWithUTM } from './api'; // Import the function
import { useLocation } from 'react-router-dom';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useParams
} from 'react-router-dom';

import Header from './pages/Header';
import Footer from './pages/Footer';
import Models from './pages/Models';
import Main from './pages/Main';
import ModelDetails from './pages/ModelDetails';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Map from './pages/Map';
import AgeVerification from './pages/AgeVerification';

import Dashboard from './pages/account/Dashboard';
import TariffsServices from './pages/account/TariffsServices';
import Recharge from './pages/account/Recharge';
import Support from './pages/account/Support';
import Logout from './pages/account/Logout';
import ProfileForm from './pages/account/ProfileForm';
import AdminLogin from './pages/admin/AdminLogin';
import PrivateTable from './pages/admin/PrivateTable';
import ReviewPrivate from './pages/admin/ReviewPrivate';

import {AppProvider} from './context/AppContext';
import {useAppContext} from './context/AppContext';
import CookieConsentDialog from './pages/CookieConsentDialog'; // Import the new component

import Info from './pages/docs/Info';
import Disclaimer from './pages/docs/Disclaimer';
import Privacy from './pages/docs/Privacy';
import Terms from './pages/docs/Terms';
import Requirements from "./pages/docs/Requirements";

import InfoCircle from './pages/InfoCircle'; // Import the InfoCircle component

/**
 * CityRoutes:
 * 1) Reads the ":city" param (msk or spb).
 * 2) Redirects invalid cities to /msk or the last selected city.
 * 3) Renders city-based pages (Header, Footer, Models, etc.).
 */
function CityRoutes() {
    const {city} = useParams();
    const {setValue, getValue} = useAppContext();

    useEffect(() => {
        if (['msk', 'spb'].includes(city)) {
            setValue('lastSelectedCity', city);
        }
    }, [city]);

    if (!['msk', 'spb'].includes(city)) {
        return <Navigate to={`/${getValue('lastSelectedCity') || 'msk'}`} replace/>;
    }

    return (
        <>
            <Header/>
            <Routes>
                <Route index element={<Models/>}/>
                <Route path="map" element={<Map/>}/>
                <Route path="private/:id" element={<ModelDetails/>}/>
            </Routes>
            <Footer/>
        </>
    );
}

function PrivateCabinetRoutes() {
    return (
        <Routes>
            <Route path="dashboard" element={<Dashboard/>}/>
            <Route path="content-requirements" element={<InfoRoutes/>}/>
            <Route path="tariffs-services" element={<TariffsServices/>}/>
            <Route path="recharge" element={<Recharge/>}/>
            <Route path="support" element={<Support/>}/>
            <Route path="logout" element={<Logout/>}/>

            {/* Ankets multi-step flow */}
            <Route path="profile" element={<ProfileForm/>}/>

            {/* Default route */}
            <Route path="*" element={<Navigate to="/me/dashboard" replace/>}/>
        </Routes>
    );
}

function AdminRoutes() {
    return (
        <>
            <Routes>
                <Route path="login" element={<AdminLogin/>}/>
                <Route path="privates" element={<PrivateTable/>}/>
                <Route path="private/:id" element={<ReviewPrivate/>}/>
            </Routes>
        </>
    );
}

function InfoRoutes() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Info/>}/>
                <Route path="disclaimer" element={<Disclaimer/>}/>
                <Route path="terms" element={<Terms/>}/>
                <Route path="privacy" element={<Privacy/>}/>
                <Route path="requirements" element={<Requirements/>}/>
            </Routes>
        </>
    );
}

function App() {
    const location = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, [location.pathname]);

    useEffect(() => {
        updateURLWithUTM(); // Append UTM on route change
    }, [location]);
    
    const {getValue} = useAppContext();
    const authToken = getValue('authToken');

    const isAdultVerified = getValue('adultVerified') === 'true';
    if (!isAdultVerified) {
        return <AgeVerification/>;
    }

    return (
        <>
            <Routes>
                <Route path="info/*" element={<InfoRoutes/>}/>

                <Route path="hfwiehfwkuehfw98ef89wehfkwejhfkjwehfkjwehfkjwe/*" element={<AdminRoutes/>}/>

                {/* Default route: Always redirect "/" to "/msk" */}
                <Route path="/" element={<Main/>}/>

                {/* City-based routes (unchanged for both authenticated and unauthenticated users) */}
                <Route path=":city/*" element={<CityRoutes/>}/>

                {/* Auth pages */}
                {!authToken ? (
                    <>
                        {/* Login and Signup available for unauthenticated users */}
                        <Route path="login" element={<Login/>}/>
                        <Route path="signup" element={<SignUp/>}/>
                    </>
                ) : (
                    <>
                        {/* Redirect login and signup to /me if user is authenticated */}
                        <Route path="login" element={<PrivateCabinetRoutes replace/>}/>
                        <Route path="signup" element={<PrivateCabinetRoutes replace/>}/>
                        <Route path="me/*" element={<PrivateCabinetRoutes/>}/>
                    </>
                )}

            </Routes>

            <InfoCircle />
            <CookieConsentDialog />
        </>
    );
}

// Wrap everything in a <Router>
export default function AppContainer() {
    return (
        <AppProvider>
            <Router>
                <App/>

                {/* Глобальные стили */}
                <style>
                    {`
                        @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600;700&family=Montserrat:wght@400;500;700&display=swap');
                        
                         body {
                            margin: 0;
                            padding: 0;
                            font-family: 'Montserrat', sans-serif;
                            background-color: #fff;
                            color: #000;
                            overflow-x: hidden; /* Скрываем горизонтальный скролл */
                          }
                    `}
                </style>
            </Router>
        </AppProvider>
    );
}
