// File: src/pages/MainPage.jsx
import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import api from "../api";

// ------------------
// Вспомогательные функции из Models.jsx
// ------------------
function getAgeSuffix(age) {
    const lastDigit = age % 10;
    const lastTwo = age % 100;
    if (lastTwo >= 11 && lastTwo <= 14) return 'лет';
    if (lastDigit === 1) return 'год';
    if (lastDigit >= 2 && lastDigit <= 4) return 'года';
    return 'лет';
}

function calculateAge(birthDateString) {
    if (!birthDateString) return null;
    const birthDate = new Date(birthDateString);
    const now = new Date();
    let age = now.getFullYear() - birthDate.getFullYear();
    const m = now.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

// Анимация плавного появления
const fadeInUp = {
    hidden: {opacity: 0, y: 40},
    visible: {opacity: 1, y: 0, transition: {duration: 0.8}},
};

// Вставляем CSS для скрытия горизонтального скролла + адаптив
function injectAdditionalStyles() {
    const style = document.createElement("style");
    style.textContent = `
    /* Прячем horizontal scroll */
    .horizontal-scroll-container {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    .horizontal-scroll-container::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 480px) {
      .girlCard {
        min-width: 320px !important;
        max-width: 320px !important;
      }
      .services-grid {
        grid-template-columns: 1fr !important;
      }
      .ctaSection {
        max-width: 320px !important;
        margin: 40px auto !important;
        padding: 0 20px !important;
      }
    }
  `;
    document.head.appendChild(style);
    return () => {
        document.head.removeChild(style);
    };
}

// Карточка девушки (точно как в Models.jsx, но чуть шире)
function GirlCard({girl}) {
    let mainPhotoUrl = "";
    if (girl.photos && girl.photos.length > 0) {
        const primaryPhoto = girl.photos.find((p) => p.isPrimary);
        mainPhotoUrl = primaryPhoto ? primaryPhoto.path : girl.photos[0].path;
    }

    // Если cityId = 2 => spb, иначе msk
    const citySlug = girl.cityId === 2 ? "spb" : "msk";

    // Возраст
    const ageNum = calculateAge(girl.birthDate);
    const ageSuffix = ageNum !== null ? getAgeSuffix(ageNum) : "";
    const ageString = ageNum ? `${ageNum} ${ageSuffix}` : "";

    const heightVal = girl.height || "";
    const bustVal = girl.bust || "";
    const priceVal = girl.price || "";
    const subwayVal = girl.subway ? girl.subway.name : "";

    // Hover эффекты
    const handleMouseEnterCard = (e) => {
        e.currentTarget.style.transform = "translateY(-5px)";
        e.currentTarget.style.boxShadow = "0 4px 12px rgba(0,0,0,0.15)";
    };
    const handleMouseLeaveCard = (e) => {
        e.currentTarget.style.transform = "translateY(0)";
        e.currentTarget.style.boxShadow = "none";
    };

    const handleContainerEnter = (e) => {
        const overlay = e.currentTarget.querySelector(".hoverOverlay");
        if (overlay) overlay.style.opacity = "1";
    };
    const handleContainerLeave = (e) => {
        const overlay = e.currentTarget.querySelector(".hoverOverlay");
        if (overlay) overlay.style.opacity = "0";
    };

    return (
        <Link
            className="girlCard"
            to={`/${citySlug}/private/${girl.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={cardStyle}
            onMouseEnter={handleMouseEnterCard}
            onMouseLeave={handleMouseLeaveCard}
        >
            <div
                style={photoContainerStyle}
                onMouseEnter={handleContainerEnter}
                onMouseLeave={handleContainerLeave}
            >
                <img
                    src={mainPhotoUrl}
                    alt={girl.name}
                    title={girl.name}
                    style={imageStyle}/>

                <div className="hoverOverlay" style={overlayStyle}>
                    <p style={{margin: "0 0 8px", fontSize: "1rem", fontWeight: "600"}}>
                        {heightVal} см │ {bustVal} бюст
                        {/*от {priceVal}₽*/}
                    </p>
                    <p style={{margin: 0, fontSize: "0.9rem"}}>{subwayVal}</p>
                </div>
            </div>

            <div style={infoContainerStyle}>
                <p style={nameStyle}>{girl.name}, {ageNum}</p>
                <p style={statsStyle}>
                    от {priceVal}₽
                    {/*{heightVal} см │ {ageString} │ {bustVal} бюст*/}
                </p>
            </div>

            <div style={dividerStyle}></div>
        </Link>
    );
}

// ------------------------------
// Главная страница
// ------------------------------
function MainPage() {
    const [girls, setGirls] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => injectAdditionalStyles(), []);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await api.get("/private", {params: {IsOnMainPage: true}});
                const data = res.data || [];

                if (data.length <= 20) {
                    setGirls(data);
                } else {
                    const shuffled = data.sort(() => 0.5 - Math.random());
                    setGirls(shuffled.slice(0, 20));
                }
            } catch (err) {
                setLoading(true);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    return (
        <>
            <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                zIndex: 9999
            }}>
                <Header shouldAnimate={true}/>
            </div>
            <div style={pageWrapperStyle}>


                {/* Hero Section */}
                <section style={heroSectionStyle}>
                    <div style={heroOverlayStyle}></div>
                    <motion.div
                        style={heroContentStyle}
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                    >
                        <h1 style={heroTitleStyle}>Marketplace 18 Plus</h1>
                        <p style={heroSubtitleStyle}>
                            Сопровождение, чувственный массаж и другие легальные услуги
                            (строго без интима).
                        </p>
                        <Link to="/msk" style={ctaButtonStyle}>
                            Перейти к выбору
                        </Link>
                    </motion.div>
                </section>

                {/* О нас: больше вертикальных отступов, заголовок по центру, текст justify */}
                <section style={aboutSectionStyle}>
                    <motion.div
                        style={{textAlign: "center", marginBottom: "24px"}} // заголовок центр
                        initial="hidden"
                        whileInView="visible"
                        viewport={{once: true, amount: 0.3}}
                        variants={fadeInUp}
                    >
                        <h2 style={sectionTitleStyle}>О нас</h2>
                    </motion.div>
                    <motion.div
                        style={{
                            textAlign: "justify", // параграфы выровнены по ширине
                            margin: "0 auto",
                            maxWidth: "600px", // чтобы строки были читабельными
                        }}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{once: true, amount: 0.3}}
                        variants={fadeInUp}
                    >
                        <p style={paragraphStyle}>
                            Добро пожаловать на Marketplace — площадку-агрегатор, где девушки
                            размещают свои объявления: эскорт, чувственный массаж, а также прочие виды досуга без
                            интима.
                        </p>

                        <p style={paragraphStyle}>
                            Мы не являемся исполнителями услуг и не
                            организуем встречи — наша задача сводится к тому, чтобы агрегировать анкеты,
                            проверять их на соответствие правилам и публиковать только лучших.
                        </p>

                        <p style={paragraphStyle}>
                            Мы не предлагаем секс или проституцию, а строго модерируем контент, чтобы
                            гарантировать легальность и безопасность. Каждая девушка проходит
                            верификацию. Присоединяйтесь к нам и откройте для себя комфортный способ
                            найти подходящую услугу.
                        </p>
                    </motion.div>
                </section>

                {/* Горизонтальный список */}
                <section style={modelsSectionStyle}>
                    <motion.h2
                        style={sectionTitleStyle} // заголовок центр
                        initial="hidden"
                        whileInView="visible"
                        variants={fadeInUp}
                        viewport={{once: true, amount: 0.2}}
                    >
                        Москва и Санкт-Петербург
                    </motion.h2>
                    <motion.div
                        className="horizontal-scroll-container"
                        style={horizontalScrollContainerStyle}
                        initial="hidden"
                        whileInView="visible"
                        variants={fadeInUp}
                    >
                        {loading &&
                            <div
                                style={{
                                    width: '100%',
                                    height: '20vh',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 9999,
                                }}
                            >
                                <style>{`
                                @keyframes spin {
                                    0%   { transform: rotate(0deg); }
                                    100% { transform: rotate(360deg); }
                                }
                            `}</style>
                                <div
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        border: '3px solid #ccc',
                                        borderTop: '3px solid #000',
                                        borderRadius: '50%',
                                        animation: 'spin 1s linear infinite',
                                    }}
                                />
                            </div>
                        }
                        {!loading &&
                            girls.map((girl) => (
                                <GirlCard key={girl.id} girl={girl}/>
                            ))}
                    </motion.div>
                </section>

                {/* Услуги */}
                <section style={servicesSectionStyle}>
                    <motion.h2
                        style={sectionTitleStyle}
                        initial="hidden"
                        whileInView="visible"
                        variants={fadeInUp}
                        viewport={{once: true, amount: 0.2}}
                    >
                        Услуги
                    </motion.h2>
                    <motion.div
                        style={servicesListStyle}
                        className="services-grid"
                        initial="hidden"
                        whileInView="visible"
                        variants={fadeInUp}
                    >
                        <div style={serviceItemStyle}>
                            <h3 style={serviceItemTitleStyle}>Сопровождение</h3>
                            <p style={paragraphStyle}>
                                Лёгкий досуг: появление на публике, общение и приятная компания.
                            </p>
                        </div>
                        <div style={serviceItemStyle}>
                            <h3 style={serviceItemTitleStyle}>Чувственный массаж</h3>
                            <p style={paragraphStyle}>
                                Расслабляющие процедуры. Никаких интим-услуг —
                                только эстетика и релакс.
                            </p>
                        </div>
                    </motion.div>
                </section>

                {/* CTA-блок, тоже с увеличенными отступами, заголовок центр, текст justify */}
                <section className="ctaSection" style={ctaSectionStyle}>
                    <motion.div
                        style={{textAlign: "center", marginBottom: "24px"}}
                        initial="hidden"
                        whileInView="visible"
                        variants={fadeInUp}
                    >
                        <h2 style={sectionTitleStyle}>Присоединяйтесь к Marketplace</h2>
                    </motion.div>
                    <motion.div
                        style={{
                            textAlign: "justify",
                            margin: "0 auto",
                            maxWidth: "600px",
                        }}
                        initial="hidden"
                        whileInView="visible"
                        variants={fadeInUp}
                    >
                        <p style={paragraphStyle}>
                            Хотите разместить анкету? Мы проверяем каждую заявку перед
                            публикацией, чтобы убедиться в соответствии правилам. Ваша безопасность
                            и репутация — наш приоритет.
                        </p>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '16px'}}>
                            <Link to="/login" style={ctaButtonStyle}>
                                Разместить анкету
                            </Link>
                        </div>
                    </motion.div>
                </section>

                <Footer/>
            </div>
        </>
    );
}

// ------------------- Стили “вне” карточки -------------------
const pageWrapperStyle = {
    position: "relative",
    minHeight: "100vh",
    backgroundColor: "#fff",
    overflowX: "hidden",
};

// Добавляем побольше вертикальных отступов
const heroSectionStyle = {
    position: "relative",
    width: "100%",
    height: "100vh",
    background: "url('/main.jpg') center center / cover no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    marginBottom: "80px",
};

const heroOverlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 0,
};

const heroContentStyle = {
    position: "relative",
    zIndex: 1,
    textAlign: "center",
    maxWidth: "600px",
    padding: "0 20px",
};

const heroTitleStyle = {
    fontSize: "3rem",
    marginBottom: "1rem",
    fontFamily: "'Playfair Display', serif",
    color: "#fff",
    lineHeight: "1.2",
};

const heroSubtitleStyle = {
    fontSize: "1.2rem",
    marginBottom: "2rem",
    color: "#fff",
};

const ctaButtonStyle = {
    backgroundColor: "#000",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "6px",
    cursor: "pointer",
    fontSize: "1rem",
    textDecoration: "none",
};

// "О нас": большие отступы сверху и снизу
const aboutSectionStyle = {
    maxWidth: "100%",
    margin: "80px auto",
    padding: "0 20px",
};

const sectionTitleStyle = {
    fontSize: "2rem",
    marginBottom: "1rem",
    fontFamily: "'Playfair Display', serif",
};

const paragraphStyle = {
    fontSize: "1rem",
    lineHeight: "1.6",
    color: "#000",
    marginBottom: "1.2rem",
    fontFamily: "'Montserrat', sans-serif",
};

// Горизонтальный список
const modelsSectionStyle = {
    maxWidth: "100%",
    margin: "80px auto",
    textAlign: "center",
};

const horizontalScrollContainerStyle = {
    display: "flex",
    gap: "24px",
    overflowX: "auto",
    padding: "0 20px",
};

// ----------- Стили карточки (чуть шире) -----------
const cardStyle = {
    display: "flex",
    flexDirection: "column",
    textDecoration: "none",
    color: "inherit",
    overflow: "hidden",
    transition: "transform 0.2s",
    minWidth: "360px",
    maxWidth: "360px",
};

const photoContainerStyle = {
    position: "relative",
    width: "100%",
    paddingTop: "150%",
    overflow: "hidden",
    backgroundColor: "#fff",
};

const imageStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
};

const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.4)",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "opacity 0.3s",
    opacity: 0,
};

const infoContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
};

const nameStyle = {
    margin: 0,
    fontSize: '1.2rem',
    fontWeight: '700',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: '1 1 auto',
    minWidth: 0,
};

const statsStyle = {
    margin: 0,
    fontSize: '1rem',
    fontWeight: '500',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    flex: '0 0 auto',
};

const dividerStyle = {
    width: "100%",
    height: "1px",
    backgroundColor: "#ddd",
    marginTop: "12px",
};

// Услуги (тоже с большими отступами)
const servicesSectionStyle = {
    maxWidth: "1000px",
    margin: "80px auto",
    padding: "0 20px",
    textAlign: "center",
};

const servicesListStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "40px",
    marginTop: "40px",
    justifyContent: "center",
};

const serviceItemStyle = {
    border: '2px solid #000',
    borderRadius: "6px",
    padding: "20px",
};

const serviceItemTitleStyle = {
    marginTop: 0,
    fontFamily: "'Playfair Display', serif",
    fontSize: "1.3rem",
};

// CTA-блок
const ctaSectionStyle = {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "600px",
    margin: "80px auto",
    padding: "0 20px",
    textAlign: "center",
};

export default MainPage;
