// src/pages/Login.js

import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import Footer from './Footer';
import api from '../api'; // Your axios instance, e.g. baseURL: 'http://localhost:5256/api'
import {useAppContext} from '../context/AppContext';

function Login() {
    const [loginValue, setLoginValue] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const {setValue} = useAppContext();

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        try {
            const requestData = {
                login: loginValue,
                password: password,
            };

            // POST to /api/auth/private/login
            const response = await api.post('/auth/private/login', requestData);
            if (response.data.success) {
                // Save the token to localStorage

                setValue('authToken', response.data.token)

                // Navigate to private cabinet's dashboard
                navigate('/me/dashboard');
            } else {
                setErrorMessage(response.data.message || 'Login failed');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An unexpected error occurred');
            }
        }
    };

    const handleReturnToMain = () => {
        navigate(`/`);
    };

    // ---- STYLES (Unchanged) ----
    const layoutStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    };

    const contentStyle = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#fafafa',
    };

    const cardStyle = {
        width: '100%',
        maxWidth: '400px',
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    };

    const titleStyle = {
        fontSize: '1.5rem',
        fontWeight: '700',
        textAlign: 'center',
        color: '#333'
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    };

    const labelStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        fontSize: '0.9rem',
        color: '#333'
    };

    const inputStyle = {
        padding: '10px 12px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        fontSize: '0.95rem',
        outline: 'none'
    };

    const buttonStyle = {
        padding: '12px',
        borderRadius: '8px',
        border: 'none',
        fontSize: '1rem',
        fontWeight: '600',
        cursor: 'pointer',
        backgroundColor: '#000',
        color: '#fff'
    };

    const returnButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#f5f5f5',
        color: '#333'
    };

    return (
        <div style={layoutStyle}>
            <div style={contentStyle}>
                <div style={cardStyle}>

                    <h2 style={titleStyle}>Вход для моделей</h2>

                    {/* Show error message if any */}
                    {errorMessage && (
                        <div style={{color: 'red', textAlign: 'center'}}>{errorMessage}</div>
                    )}

                    <form onSubmit={handleLoginSubmit} style={formStyle}>
                        <label style={labelStyle}>
                            Логин
                            <input
                                type="text"
                                style={inputStyle}
                                value={loginValue}
                                onChange={(e) => setLoginValue(e.target.value)}
                                placeholder="Введите логин"
                            />
                        </label>
                        <label style={labelStyle}>
                            Пароль
                            <input
                                type="password"
                                style={inputStyle}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Введите пароль"
                            />
                        </label>
                        <button type="submit" style={buttonStyle}>
                            Войти
                        </button>
                    </form>

                    <button onClick={handleReturnToMain} style={returnButtonStyle}>
                        На главную
                    </button>

                    <p style={{marginTop: '12px', fontSize: '0.9rem', textAlign: 'center'}}>
                        Нет аккаунта? <Link to="/signup">Зарегистрироваться</Link>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Login;
