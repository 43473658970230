import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import api from '../api';

const API_KEY_YANDEX_MAPS = process.env.REACT_APP_API_KEY_YANDEX_MAPS;

function Map() {
    const {city} = useParams();
    const cityData = {
        msk: {name: 'Москва', coordinates: [55.7558, 37.6173], id: 1},
        spb: {name: 'Санкт-Петербург', coordinates: [59.9343, 30.3351], id: 2},
    };

    const selectedCity = cityData[city] || cityData['msk'];
    const [centerLat, centerLng] = selectedCity.coordinates;
    const cityIdWanted = selectedCity.id;
    const mapContainerRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const scriptLoadedRef = useRef(false);
    const [loading, setLoading] = useState(true);

    const [models, setModels] = useState([]);

    // 1) Fetch models
    useEffect(() => {
        async function fetchModels() {
            try {
                const response = await api.get('/private', {
                    params: {
                        cityId: cityIdWanted,
                    },
                });
                setModels(response.data);
                setLoading(false);
            } catch (err) {
                // setError('Ошибка при загрузке данных');
            }
        }

        fetchModels();
    }, [cityIdWanted]);

    // 2) Load Yandex Maps
    useEffect(() => {
        const loadYandexMaps = () => {
            return new Promise((resolve) => {
                if (window.ymaps && window.ymaps.Map) {
                    resolve();
                } else if (!scriptLoadedRef.current) {
                    scriptLoadedRef.current = true;
                    const script = document.createElement('script');
                    script.src = `https://api-maps.yandex.ru/2.1/?apikey=${API_KEY_YANDEX_MAPS}&lang=ru_RU`;
                    script.async = true;
                    script.onload = () => resolve();
                    document.body.appendChild(script);
                } else {
                    const checkInterval = setInterval(() => {
                        if (window.ymaps && window.ymaps.Map) {
                            clearInterval(checkInterval);
                            resolve();
                        }
                    }, 500);
                }
            });
        };

        loadYandexMaps().then(() => {
            if (!mapContainerRef.current) return;

            window.ymaps.ready(() => {
                // Destroy old instance if present
                if (mapInstanceRef.current) {
                    mapInstanceRef.current.destroy();
                    mapInstanceRef.current = null;
                }
                if (!mapContainerRef.current) return;

                const map = new window.ymaps.Map(mapContainerRef.current, {
                    center: [centerLat, centerLng],
                    zoom: 12,
                    controls: ['zoomControl']
                });
                mapInstanceRef.current = map;

                // 3) Create clusterer
                const clusterer = new window.ymaps.Clusterer({
                    preset: 'islands#blackClusterIcons',
                    groupByCoordinates: false,
                    clusterDisableClickZoom: true, // No auto-zoom on cluster click
                    clusterBalloonContentLayout: 'cluster#balloonCarousel',
                    clusterBalloonItemContentLayout: 'cluster#balloonCarouselItemContent',
                    clusterOpenBalloonOnClick: true,
                    clusterHideIconOnBalloonOpen: false,
                    geoObjectHideIconOnBalloonOpen: false,
                    gridSize: 128,
                });

                // 4) Create placemarks
                const placemarks = models.map(model => {
                    if (!model.latitude || !model.longitude) return null;
                    const [lat, lng] = [model.latitude, model.longitude];
                    const primaryPhoto = model.photos.find(p => p.isPrimary) || model.photos[0];
                    const price = model.price ? `от ${model.price} ₽` : "Цена не указана";

                    // Minimal custom icon logic
                    const CustomIconLayout = window.ymaps.templateLayoutFactory.createClass(`
                        <img src="{{ properties.image }}" style="
                            width: 50px;
                            height: 50px;
                            object-fit: cover;
                            object-position: top;
                            border-radius: 8px;
                            pointer-events: auto;
                            cursor: pointer;
                            z-index: 1000;
                        " />
                    `, {
                        build: function () {
                            CustomIconLayout.superclass.build.call(this);

                            const placemark = this.getData().geoObject;
                            placemark.events.remove('click');
                            placemark.events.remove('balloonclose');

                            // Single placemark click => open balloon
                            placemark.events.add('click', () => {
                                if (!placemark.balloon.isOpen()) {
                                    placemark.balloon.open();
                                }
                            });

                            // When balloon closes, reset function
                            placemark.events.add('balloonclose', () => {
                                setTimeout(() => {
                                    placemark.balloon.isOpen = () => false;
                                }, 100);
                            });
                        }
                    });

                    // ==> THE ONLY “MINIMAL CHANGE” IS RIGHT HERE: we define balloonContent
                    //     so the photo is on the left, text is on the right (using inline flex)
                    const placemark = new window.ymaps.Placemark(
                        [lat, lng],
                        {
                            hintContent: model.name,
                            balloonContent: `
                                <div style="
                                    display: flex; 
                                    align-items: flex-start; 
                                    justify-content: flex-start;
                                    gap: 12px;
                                    font-family: Arial, sans-serif; 
                                    padding: 10px; 
                                    border-radius: 8px; 
                                    background: #fff; 
                                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                                    text-align: left;
                                ">
                                    <!-- PHOTO on the LEFT -->
                                    <img 
                                        src="${primaryPhoto?.path}" 
                                        alt="${model.name}" 
                                        style="
                                            width: 100px; 
                                            height: auto; 
                                            border-radius: 8px; 
                                            object-fit: contain;
                                        " 
                                    />
                                    <!-- TEXT on the RIGHT -->
                                    <div>
                                        <p style="margin: 0; font-size: 16px; font-weight: bold; color: #333;">
                                            ${model.name}
                                        </p>
                                        <p style="margin: 4px 0; font-size: 14px; color: #555;">
                                            ${price}
                                        </p>
                                        <a 
                                            href="/${city}/private/${model.id}" 
                                            target="_blank" 
                                            style="
                                                display: inline-block; 
                                                padding: 6px 12px; 
                                                font-size: 14px; 
                                                font-weight: bold; 
                                                color: #fff; 
                                                background: #000; 
                                                text-decoration: none; 
                                                border-radius: 4px;
                                            "
                                        >
                                            Подробнее
                                        </a>
                                    </div>
                                </div>
                            `,
                            image: primaryPhoto?.path || '',
                        },
                        {
                            iconLayout: CustomIconLayout,
                            iconShape: {
                                type: 'Rectangle',
                                coordinates: [[0, 0], [50, 50]]
                            },
                            iconImageSize: [50, 50],
                            iconImageOffset: [-25, -25],
                            hasBalloon: true,
                            hideIconOnBalloonOpen: false,
                        }
                    );

                    // Keep balloon from auto-panning
                    placemark.options.set('balloonAutoPan', false);
                    return placemark;
                }).filter(Boolean);

                // 5) Add placemarks to clusterer, then clusterer to map
                clusterer.add(placemarks);
                map.geoObjects.add(clusterer);
            });
        });

        // Cleanup on unmount
        return () => {
            if (mapInstanceRef.current) {
                mapInstanceRef.current.destroy();
                mapInstanceRef.current = null;
            }
        };
    }, [models, centerLat, centerLng]);

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    backgroundColor: '#fff',
                }}
            >
                <style>{`
                    @keyframes spin {
                        0%   { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}</style>
                <div
                    style={{
                        width: '30px',
                        height: '30px',
                        border: '3px solid #ccc',
                        borderTop: '3px solid #000',
                        borderRadius: '50%',
                        animation: 'spin 1s linear infinite',
                    }}
                />
            </div>
        );
    }

    return (
        <div
            ref={mapContainerRef}
            id="map"
            style={{width: '100%', height: 'calc(100vh - 190px)'}}
        />
    );
}

export default Map;
