// File: src/pages/ankets/ReviewPrivate.jsx
import React, {useState, useEffect, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import {useAppContext} from '../../context/AppContext';

// Convert cityId 1->"Москва", 2->"Санкт-Петербург", else "Че за хуйня"
const getCityName = (cityId) => {
    switch (cityId) {
        case 1:
            return 'Москва';
        case 2:
            return 'Санкт-Петербург';
        default:
            return '';
    }
};

// Convert numeric status to a readable string
const getStatusString = (status) => {
    switch (status) {
        case 1:
            return 'Заполните анкету';
        case 2:
            return 'Ожидает модерации';
        case 3:
            return 'Анкета активна';
        case 4:
            return 'Анкета отклонена. Напишите в поддержку';
        default:
            return 'Неизвестный статус. Напишите в поддержку';
    }
};

function ReviewPrivate() {
    const {getValue} = useAppContext();
    const {id} = useParams(); // Grab :id from the route, e.g. /private/123

    const [privateData, setPrivateData] = useState(null);

    // For updating reason
    const [rejectReason, setRejectReason] = useState('');
    // Новое поле для админа: approve (галочка)
    const [approve, setApprove] = useState(false);

    const [moderator, setModerator] = useState('');


    // For loading / error / success states
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // --- Photo Carousel state ---
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

    // 1) Fetch the private's data
    useEffect(() => {
        const fetchPrivate = async () => {
            setLoading(true);
            setError('');
            setSuccess('');
            try {
                const adminAuthToken = getValue('adminAuthToken');
                const response = await api.get(`/admin/private/${id}`, {
                    headers: {Authorization: `Bearer ${adminAuthToken}`},
                });
                setPrivateData(response.data);
                setModerator(response.data.moderator);
                setRejectReason(response.data.rejectReason);
            } catch (err) {
                setError('Ошибка при загрузке данных.');
            } finally {
                setLoading(false);
            }
        };

        fetchPrivate();
    }, [id, getValue]);

    // 2) PUT request => body = { approve, rejectReason }
    const handleSubmit = async () => {
        setSubmitLoading(true);
        setError('');
        setSuccess('');

        try {
            const adminAuthToken = getValue('adminAuthToken');
            const url = `/admin/private/${id}`;

            await api.put(
                url,
                {
                    approve,
                    rejectReason,
                    moderator
                },
                {
                    headers: {Authorization: `Bearer ${adminAuthToken}`},
                }
            );
            setSuccess('Успешно обновлено!');
        } catch (err) {
            setError('Ошибка при обновлении. Попробуйте снова.');
        } finally {
            setSubmitLoading(false);
        }
    };

    // Carousel handlers
    const openGallery = (index) => {
        setCurrentPhotoIndex(index);
        setGalleryOpen(true);
    };
    const closeGallery = () => {
        setGalleryOpen(false);
    };
    const prevPhoto = useCallback(() => {
        if (!privateData || !privateData.photos) return;
        setCurrentPhotoIndex((prev) =>
            prev === 0 ? privateData.photos.length - 1 : prev - 1
        );
    }, [privateData]);
    const nextPhoto = useCallback(() => {
        if (!privateData || !privateData.photos) return;
        setCurrentPhotoIndex((prev) =>
            prev === privateData.photos.length - 1 ? 0 : prev + 1
        );
    }, [privateData]);

    // Close on ESC or arrow keys
    useEffect(() => {
        if (!galleryOpen) return;
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                closeGallery();
            } else if (e.key === 'ArrowLeft') {
                prevPhoto();
            } else if (e.key === 'ArrowRight') {
                nextPhoto();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [galleryOpen, prevPhoto, nextPhoto]);

    if (loading) {
        return (
            <div style={{padding: '16px'}}>
                <h1>Загрузка...</h1>
            </div>
        );
    }

    if (!privateData && !loading) {
        return (
            <div style={{padding: '16px'}}>
                <h1>Нет данных</h1>
            </div>
        );
    }

    // Render the private data
    const {
        name,
        description,
        birthDate,
        phone,
        telegram,
        whatsApp,
        cityId,
        latitude,
        longitude,
        height,
        address,
        bust,
        foot,
        weight,
        price,
        status: currentStatus,
        subways,
        signaCode,
        photos,
    } = privateData || {};

    // Разделяем обычные фото и фото для верификации
    const allPhotos = photos || [];
    const normalPhotoIndices = [];
    const verificationPhotoIndices = [];

    allPhotos.forEach((p, i) => {
        if (p.isForVerification) {
            verificationPhotoIndices.push(i);
        } else {
            normalPhotoIndices.push(i);
        }
    });

    // Simple modal overlay for gallery
    const GalleryModal = () => {
        if (!galleryOpen || !allPhotos.length) return null;

        const currentPhoto = allPhotos[currentPhotoIndex];

        // Inline styles for minimal changes
        const overlayStyle = {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };
        const imageStyle = {
            maxWidth: '90%',
            maxHeight: '80%',
            objectFit: 'contain',
        };
        const closeIconStyle = {
            position: 'absolute',
            top: '20px',
            right: '20px',
            fontSize: '2rem',
            color: '#fff',
            cursor: 'pointer',
        };
        const navButtonStyle = {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: '3rem',
            color: '#fff',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
        };

        return (
            <div style={overlayStyle} onClick={closeGallery}>
                <span style={closeIconStyle} onClick={closeGallery}>
                    &times;
                </span>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        prevPhoto();
                    }}
                    style={{...navButtonStyle, left: '20px'}}
                >
                    ‹
                </button>
                <img
                    src={currentPhoto.path}
                    alt={`Photo ${currentPhoto.id}`}
                    style={imageStyle}
                    onClick={(e) => e.stopPropagation()} // prevent closing if click on image
                />
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        nextPhoto();
                    }}
                    style={{...navButtonStyle, right: '20px'}}
                >
                    ›
                </button>
            </div>
        );
    };

    return (
        <div style={{padding: '16px'}}>
            <h1>Private ID: {id}</h1>

            <div style={{marginBottom: '16px'}}>
                <strong>Имя:</strong> {name}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Описание:</strong> {description}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Дата рождения:</strong> {birthDate}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Телефон:</strong> {phone}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Telegram:</strong> {telegram}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>WhatsApp:</strong> {whatsApp}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Город:</strong> {getCityName(cityId)}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Координаты:</strong> lat={latitude}, lng={longitude}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Рост (height):</strong> {height}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Адрес (address):</strong> {address}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Грудь (bust):</strong> {bust}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Размер ноги (foot):</strong> {foot}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Вес (weight):</strong> {weight}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Цена (price):</strong> {price}
            </div>
            <div style={{marginBottom: '16px'}}>
                <strong>Текущий статус (с сервера):</strong>{' '}
                {getStatusString(currentStatus)}
            </div>

            {/* Subways */}
            <div style={{marginBottom: '16px'}}>
                <strong>Станции метро:</strong>{' '}
                {subways && subways.length > 0 ? (
                    subways.map((sw) => (
                        <span key={sw.id} style={{marginRight: '8px'}}>
                            #{sw.id}
                        </span>
                    ))
                ) : (
                    <span>Нет метро</span>
                )}
            </div>

            {/* Обычные фото */}
            <div style={{marginBottom: '16px'}}>
                <strong>Фотографии (основные):</strong>
                {normalPhotoIndices.length > 0 ? (
                    <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '8px'}}>
                        {normalPhotoIndices.map((idx) => {
                            const photo = allPhotos[idx];
                            return (
                                <div
                                    key={photo.id}
                                    style={{marginRight: '8px', marginBottom: '8px'}}
                                >
                                    <img
                                        src={photo.path}
                                        alt={`Photo ${photo.id}`}
                                        style={{width: '120px', height: 'auto', cursor: 'pointer'}}
                                        onClick={() => openGallery(idx)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div>Нет основных фотографий</div>
                )}
            </div>

            <div style={{marginBottom: '16px'}}>
                <strong>Signa Code:</strong> {signaCode}
            </div>

            {/* Фотографии для верификации */}
            <div style={{marginBottom: '16px'}}>
                <strong>Фотографии (верификация):</strong>
                {verificationPhotoIndices.length > 0 ? (
                    <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '8px'}}>
                        {verificationPhotoIndices.map((idx) => {
                            const photo = allPhotos[idx];
                            return (
                                <div
                                    key={photo.id}
                                    style={{marginRight: '8px', marginBottom: '8px'}}
                                >
                                    <img
                                        src={photo.path}
                                        alt={`Photo ${photo.id}`}
                                        style={{width: '120px', height: 'auto', cursor: 'pointer'}}
                                        onClick={() => openGallery(idx)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div>Нет фотографий для верификации</div>
                )}
            </div>

            <div style={{marginBottom: '16px'}}>
                <label style={{display: 'inline-flex', alignItems: 'center'}}>
                    <input
                        type="checkbox"
                        checked={approve}
                        onChange={(e) => setApprove(e.target.checked)}
                        style={{marginRight: '8px'}}
                    />
                    Approved
                </label>
            </div>

            {/* Комментарий для rejectReason */}
            <div style={{marginBottom: '16px'}}>
                <label>
                    Сообщение (модераторский комментарий):
                    <textarea
                        style={{display: 'block', width: '100%', height: '100px', marginTop: '8px'}}
                        value={rejectReason}
                        onChange={(e) => setRejectReason(e.target.value)}
                    />
                </label>
            </div>

            <div style={{marginBottom: '16px'}}>
                <label>
                    Модератор:
                    <textarea
                        style={{display: 'block', width: '100%', height: '50px', marginTop: '8px'}}
                        value={moderator}
                        onChange={(e) => setModerator(e.target.value)}
                    />
                </label>
            </div>

            <button
                onClick={handleSubmit}
                disabled={submitLoading}
                style={{
                    padding: '12px 20px',
                    fontSize: '1rem',
                    backgroundColor: '#000',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                }}
            >
                {submitLoading ? 'Отправка...' : 'Отправить'}
            </button>

            {error && (
                <div style={{color: 'red', marginBottom: '16px'}}>{error}</div>
            )}
            {success && (
                <div style={{color: 'green', marginBottom: '16px'}}>{success}</div>
            )}

            {/* Gallery modal */}
            <GalleryModal/>
        </div>
    );
}

export default ReviewPrivate;
