import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';

function Logout() {
    // const [_, setAuthToken] = useLocalStorage('authToken', null); // Sync token with state
    const navigate = useNavigate();
    const { setValue } = useAppContext();

    useEffect(() => {
        setValue('authToken', null); // Clear the token
        localStorage.removeItem('privateProfile');
        navigate('/'); // Redirect to home or login
    }, [setValue, navigate]);

    return null; // Optional: add "Logging out..." message or spinner
}

export default Logout;
