import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Function to load Umami utils script
function loadUmamiAnalytics() {
    const script = document.createElement('script');
    script.defer = true;
    script.src = 'https://cloud.umami.is/script.js';
    script.setAttribute('data-website-id', process.env.REACT_APP_DATA_WEBSITE_ID);
    document.head.appendChild(script);
}

loadUmamiAnalytics();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App/>
);

reportWebVitals();
