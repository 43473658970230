import React, {useEffect, useState} from 'react';
import {useAppContext} from '../context/AppContext';

function AgeVerification() {
    const [isAdultVerified, setIsAdultVerified] = useState(false);
    const {getValue, setValue} = useAppContext();

    const handleAgeRejected = () => {
        window.location.href = 'https://google.com';
    };

    useEffect(() => {
        if (getValue('adultVerified') === 'true') {
            setIsAdultVerified(true);
        }
    }, []);

    const handleConfirm = () => {
        setValue('adultVerified', 'true');
        setIsAdultVerified(true);
    };

    const handleReject = () => {
        handleAgeRejected()
    };

    if (isAdultVerified) {
        return null; // Возвращаем null, если пользователь уже подтвердил возраст
    }

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Только для взрослых 18+</h2>
            <p style={styles.text}>
                На сайте представлены фото- и видеоматериалы 18+, а также информация об услугах, доступных только для совершеннолетних. Для входа на сайт вам должно быть не менее 18 лет.
            </p>
            <div style={styles.buttonGroup}>
                <button style={styles.buttonPrimary} onClick={handleConfirm}>
                    Мне уже есть 18 лет
                </button>
                <button style={styles.buttonSecondary} onClick={handleReject}>
                    Покинуть сайт
                </button>
            </div>
        </div>
    );
}

const styles = {
    container: {
        height: '100vh', // Высота контейнера на весь экран
        display: 'flex', // Включаем flexbox
        flexDirection: 'column', // Располагаем элементы вертикально
        alignItems: 'center', // Центрируем элементы по горизонтальной оси
        justifyContent: 'center', // Центрируем элементы по вертикальной оси
        padding: '16px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
        color: '#333',
        boxSizing: 'border-box', // Учитываем padding при расчёте размеров
        margin: '0', // Убираем отступы у родителя
    },
    heading: {
        fontSize: '24px',
        marginBottom: '16px',
        fontWeight: 'bold',
    },
    text: {
        maxWidth: '450px',
        marginBottom: '24px',
        fontSize: '16px',
        lineHeight: '1.5',
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'column', // Располагаем кнопки вертикально
        gap: '12px', // Расстояние между кнопками
        width: '100%', // Растягиваем кнопки на всю ширину контейнера (опционально)
        maxWidth: '300px', // Ограничиваем максимальную ширину кнопок
    },
    buttonPrimary: {
        padding: '10px 24px',
        fontSize: '16px',
        fontWeight: 'bold',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#000',
        color: '#fff',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        width: '100%', // Кнопка занимает всю ширину родителя
    },
    buttonSecondary: {
        padding: '10px 24px',
        fontSize: '16px',
        fontWeight: 'bold',
        border: '1px solid #ccc',
        borderRadius: '4px',
        backgroundColor: '#fff',
        color: '#333',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        width: '100%', // Кнопка занимает всю ширину родителя
    },
};

export default AgeVerification;
