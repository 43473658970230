import React from 'react';
import { Link } from 'react-router-dom';

const Info = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Документы</h1>
            <ul style={styles.list}>
                <li style={styles.listItem}>
                    <Link to="/info/disclaimer" style={styles.link}>
                        Отказ от ответственности
                    </Link>
                </li>
                <li style={styles.listItem}>
                    <Link to="/info/terms" style={styles.link}>
                        Условия использования
                    </Link>
                </li>
                <li style={styles.listItem}>
                    <Link to="/info/privacy" style={styles.link}>
                        Политика конфиденциальности
                    </Link>
                </li>
                <li style={styles.listItem}>
                    <Link to="/info/requirements" style={styles.link}>
                        Требования к контенту
                    </Link>
                </li>
            </ul>
        </div>
    );
};

// Define the styles
const styles = {
    container: {
        padding: '30px',
        borderRadius: '8px',
        maxWidth: '600px',
        margin: '50px auto',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        textAlign: 'center',
        fontSize: '24px',
        color: '#333',
        marginBottom: '20px',
    },
    list: {
        listStyle: 'none',  // Removes default bullet points
        padding: '0',
        margin: '0',
    },
    listItem: {
        margin: '10px 0',
    },
    link: {
        color: '#007BFF',  // Blue color
        textDecoration: 'none',  // Removes underline
        fontWeight: '500',
        fontSize: '18px',
        transition: 'color 0.3s ease',
    },
};

export default Info;
