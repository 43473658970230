// src/pages/SignUp.jsx

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Footer from './Footer';
import api from '../api'; // Import your axios instance

function SignUp() {
    const [loginValue, setLoginValue] = useState('');
    const [password, setPassword] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false); // for success popup

    const navigate = useNavigate();

    const handleSignUpSubmit = async (e) => {
        e.preventDefault();

        try {
            // Prepare request body
            const requestBody = {
                login: loginValue,
                password: password,
                birthDate: birthDate || null,
            };

            const response = await api.post('/auth/private/register', requestBody);
            if (response.data.success) {
                // Show success popup
                setShowSuccess(true);
                setErrorMessage(null);

                // After 2 seconds, navigate to /login
                setTimeout(() => {
                    navigate('/login');
                }, 3000);

            } else {
                // Show server error message
                setErrorMessage(response.data.message || 'Registration failed');
                setShowSuccess(false);
            }
        } catch (error) {
            // Handle network/axios errors
            // console.error('[SignUp] Error:', error);

            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message || 'Server error occurred');
            } else {
                setErrorMessage('An unexpected error occurred');
            }
            setShowSuccess(false);
        }
    };

    const handleReturnToMain = () => {
        navigate(`/`);
    };

    // ---- STYLES (Unchanged) ----
    const layoutStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    };

    const contentStyle = {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#fafafa',
        fontFamily: "'Montserrat', sans-serif"
    };

    const cardStyle = {
        width: '100%',
        maxWidth: '400px',
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        position: 'relative'
    };

    const titleStyle = {
        fontSize: '1.5rem',
        fontWeight: '700',
        textAlign: 'center',
        color: '#333'
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    };

    const labelStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        fontSize: '0.9rem',
        color: '#333'
    };

    const inputStyle = {
        padding: '10px 12px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        fontSize: '0.95rem',
        outline: 'none'
    };

    const buttonStyle = {
        padding: '12px',
        borderRadius: '8px',
        border: 'none',
        fontSize: '1rem',
        fontWeight: '600',
        cursor: 'pointer',
        backgroundColor: '#000',
        color: '#fff'
    };

    const returnButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#f5f5f5',
        color: '#333'
    };

    // Toast popup styles
    const toastStyle = {
        position: 'absolute',
        top: '-50px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#000',
        color: '#fff',
        padding: '8px 16px',
        // margin: '16px auto',
        borderRadius: '4px',
        fontSize: '0.9rem',
        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
        animation: 'fadeIn 0.5s',
    };

    return (
        <div style={layoutStyle}>
            <div style={contentStyle}>
                <div style={cardStyle}>

                    {/* Success Toast */}
                    {showSuccess && (
                        <div style={toastStyle}>
                            Аккаунт успешно создан, вы будете перенаправлены на страницу входа...
                        </div>
                    )}

                    <h2 style={titleStyle}>Регистрация</h2>

                    {/* Error message */}
                    {errorMessage && (
                        <div style={{color: 'red', marginBottom: '8px', textAlign: 'center'}}>
                            {errorMessage}
                        </div>
                    )}

                    <form onSubmit={handleSignUpSubmit} style={formStyle}>
                        <label style={labelStyle}>
                            Логин
                            <input
                                type="text"
                                style={inputStyle}
                                value={loginValue}
                                onChange={(e) => setLoginValue(e.target.value)}
                                placeholder="Придумайте логин"
                            />
                        </label>

                        <label style={labelStyle}>
                            Пароль
                            <input
                                type="password"
                                style={inputStyle}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Придумайте пароль"
                            />
                        </label>

                        <label style={labelStyle}>
                            Дата рождения
                            <input
                                type="date"
                                style={inputStyle}
                                value={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                            />
                        </label>

                        <button type="submit" style={buttonStyle}>
                            Создать аккаунт
                        </button>
                    </form>

                    <button onClick={handleReturnToMain} style={returnButtonStyle}>
                        На главную
                    </button>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default SignUp;
